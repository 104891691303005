(function () {
    "use strict";


    app.lotQuickInfo = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.lot-quick-info-wrap',
            info_block: '.lot-quick-info',
            status_header: '.lot-quick-info-status-header',
            menuItem: '.lot-quick-info-menu-item',
            menuItemLink: '.lot-quick-info-menu-item a',
            blocks: '.lot-page-auto-block',
            parent: '.container'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function () {
            if ($(this._selectors.container).length) {
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._elements.parent = this._elements.container.parents(this._selectors.parent).first();
                this._listeners();
            }
        },
        /**
         * Смотрим какой блок в данный момент виден и меняем активыный пункт меню
         * @param {Number} window_top - $(window).scrollTop()
         * @private
         */
        _blockVisible: function (window_top) {
            var that = this;
            this._elements.blocks.filter(':visible').each(function () {
                var $item = $(this),
                    offset = $item.offset(),
                    height = $item.outerHeight();
                if(offset.top <= window_top + 100 && offset.top + height >= window_top + 100 + app.vars.windowHeight ){
                    var id = '#' + $item.attr('id');
                    that._elements.menuItem.removeClass('active');
                    that._elements.menuItemLink.filter('[href="'+id+'"]').parents(that._selectors.menuItem).addClass('active');
                }
            });
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function () {
            var $body = $('body'),
                namespace = 'lmc.lot_quick_info';
            $body.onOne('click', this._selectors.menuItemLink, namespace, this._clickMenuLink.bind(this));
            $body.onOne(app.notification.events.update, '', namespace, this._statusUpdate.bind(this));
        },
        /**
         * Обрабатываем нажатие на пункт меню
         * @param {Event} e
         * @private
         */
        _clickMenuLink: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget),
                $parent = $item.parents(this._selectors.menuItem),
                id = $item.attr('href');
            if ($parent.is(':visible')) {
                this._elements.menuItem.removeClass('active');
                $parent.addClass('active');
                if (id) {
                    $(window).scrollTo($(id).offset().top - 80, {duration: 500});
                }
            }
        },

        /**
         * Получаем информацию по аукционам и обновляем шапку quick info
         * @param e
         * @private
         */
        _statusUpdate: function (e, data) {
            if(this._elements.status_header.length && data && data.auction) {
                var auc_id = this._elements.info_block.data('auctionId').toString();
                var user_id = this._elements.status_header.data('user').toString();
                if(auc_id && user_id) {
                    var current_auc = _.findWhere(data.auction, {id: auc_id});
                    if(user_id === current_auc.user_id) {
                        this._elements.status_header.removeClass('losing').addClass('leading');
                    } else {
                        this._elements.status_header.removeClass('leading').addClass('losing');
                    }
                }

            }
        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function () {
            if (this._init) {

            }
        },
        /**
         * Функция обработки скрола окна
         */
        scroll: function () {
            if (this._init) {
                var window_top = $(window).scrollTop();
                var container_top = this._elements.container.offset().top;
                var parent_top = this._elements.parent.offset().top;
                var parent_all_height = this._elements.parent.offset().top + this._elements.parent.innerHeight();
                var container_all_height = this._elements.container.outerHeight() + container_top + 50;
                this._elements.container.toggleClass('sticky', parent_top - 50 < window_top);
                this._blockVisible(window_top);
//                this._elements.container.toggleClass('bottom', container_all_height >= parent_all_height);

            }
        }
    };
})();