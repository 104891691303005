(function(){
    "use strict";
    app.auth = {
        _init: false,
        _selectors: {
            form: '.form-auth',
            formRegistration: '.form-registration',
            restoreLink: '.restore_password_link',
            restoreForm: '.form-restore',
            authLink: '.auth_link',

        },
        _elements: {},
        init: function(){
            if($(this._selectors.form).length){
                this._elements = app.elementsParse(this._selectors);
                app.ajaxForm(this._elements.form);
                app.ajaxForm(this._elements.formRegistration, {
                    preSubmit: this._handleRegistrationSubmit.bind(this)
                });
                app.ajaxForm(this._elements.restoreForm, {
                   successCallback: this._formRestoreSuccess.bind(this)
                });
                this._listener();
            }
        },
        _listener: function(){
            this._elements.restoreLink.click(this._formAuthToggle.bind(this, true));
            this._elements.authLink.click(this._formAuthToggle.bind(this, false));
        },
        _formAuthToggle: function(state, e){
            if(e){
                e.preventDefault();
            }
            this._elements.form.toggle(!state);
            this._elements.restoreForm.toggle(state);
        },
        _formRestoreSuccess: function(){
            var email = this._elements.restoreForm.find('[name="email"]').val();
            this._elements.form.find('[name="user[email]"]').val(email);
            this._formAuthToggle(false);
        },
        _handleRegistrationSubmit: function(values, $form) {
            var $agreement_checkbox = $form.find('.register-agreement').find('input[type="checkbox"]');
            var agreement_flag = $agreement_checkbox.prop('checked');
            if(agreement_flag) {
                return true;
            } else {
                app.toast.error('Необходимо ознакомиться с договором и регламентом для завершения регистрации');
                return false;
            }

        }
    };
})();