/**
 * Created by ursus on 14.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";

    app.addLot = {
        _selectors: {
            container: '.lot-add',
            selectMark: '[name="car[id_car_mark]"]',
            selectModels: '[name="car[id_car_model]"]',
            selectSeries: '[name="car[id_car_serie]"]',
            selectModification: '[name="car[id_car_modification]"]',
            year: '[name="car[year]"]',
        },
        urls: {
            marks: '/lots/auto/marks',
            models: '/lots/auto/models',
            serie: '/lots/auto/series',
            modification: '/lots/auto/modification',
            characteristic: '/lots/auto/characteristic'
        },
        characteristicSelectorsMap: {},
        _elements: {},
        year: 0,
        init: function () {
            if ($(this._selectors.container).length) {
                this._elements = app.elementsParse(this._selectors, this._selectors.container);
                this._listener();
                this.year = +this._elements.year.val();
                this._modificationSelect();
            }
        },

        _modificationSelect: function (options) {
            options = options || {};
            options = $.extend(options, {
                templateResult: this._formatModificationSelect.bind(this)
            });
            this._elements.selectModification.select2(options);

        },
        _seriaSelect: function (options) {
            options = options || {};
            options = $.extend(options, {
                templateResult: this._formatSerieSelect.bind(this)
            });
            this._elements.selectSeries.select2(options);

        },
        _seriaId: 0,
        _formatModificationSelect: function (state) {
            if (!state.id) {
                return state.text;
            }
            if (this.year) {
                var $el = $(state.element);
                var date_start = +$el.data('startProductionYear'),
                    date_end = +$el.data('endProductionYear'),
                    seria_id = +$el.data('idCarSerie') || 0;

               if(seria_id == this._seriaId){
                   if (date_start && date_end) {
                       if (date_start <= this.year && this.year <= date_end) {
                           return state.text;
                       }
                   } else if (date_start) {
                       if (date_start <= this.year) {
                           return state.text;
                       }
                   } else if (date_end) {
                       if (date_end >= this.year) {
                           return state.text;
                       }
                   }
               }

            } else {
                return state.text;
            }
        },
        _modificationData: [],
        _parseModificationData: function ($options) {
            var that = this;
            $options.each(function () {
                var $option = $(this);
                var data_option = {
                    date_start: +$option.data('startProductionYear') || 0,
                    date_end: +$option.data('endProductionYear') || 0,
                    seria_id: +$option.data('idCarSerie') || 0,
                    id: +$option.data('id') || 0
                };
                that._modificationData.push(data_option);
            });
        },
        _isSeriaYear: function (seria_id, year) {
            return _.find(this._modificationData, function (item) {
                if (seria_id == item.seria_id) {
                    if (item.date_end && item.date_start) {
                        return item.date_start <= year && item.date_end >= year;
                    } else if (item.date_start) {
                        return item.date_start <= year;
                    } else if (item.date_end) {
                        return  item.date_end >= year;
                    }
                }
                return false;
            });


        },
        _formatSerieSelect: function (state) {
            if(!this.year){
                this.year = +this._elements.year.val() || 0;
            }
            if (!state.id || !this.year) {
                return state.text;
            }
            var $el = $(state.element);
            var seria_id = +$el.data('id');
            if(seria_id && this._isSeriaYear(seria_id, this.year)){
                return state.text;
            }
        },
        _listener: function () {
            this._elements.selectMark.on('select2:open', this._marksLoad.bind(this));
            this._elements.selectModels.on('select2:open', this._markChange.bind(this));
            this._elements.selectModels.on('select2:open', this._modelsChange.bind(this));
            this._elements.selectModification.on('select2:open', this._seriesChange.bind(this));
            this._elements.selectMark.change(this._markChange.bind(this));
            this._elements.selectModels.change(this._modelsChange.bind(this));
            this._elements.selectSeries.change(this._seriesChange.bind(this));
            this._elements.selectModification.change(this._modificationChange.bind(this));
            this._elements.year.change(this._yearChange.bind(this));
        },
        _yearChange: function () {
            this.year = +this._elements.year.val();
            this._elements.selectSeries.trigger('change');
            this._elements.selectSeries.select2('open');
            /*this._modificationSelect();this._elements*/
        },
        _marksLoad: function (e, callback) {
            if (!this._marksLoad.data) {
                var that = this;
                $.get(this.urls.marks).done(function (data) {
                    that._marksLoad.data = data;
                    that._elements.selectMark.html(data);
                    var value = that._elements.selectMark.data('value');
                    if (value) {

                        that._elements.selectMark.val(value);
                    }
                    that._elements.selectMark.select2();

                    if (callback) {
                        callback();
                    } else {
                        that._elements.selectMark.select2('open');
                    }
                }).fail(function () {
                    app.toast.error("Произошла ошибка");
                });
            }
        },
        _markChange: function (e, callback) {
            var value = this._elements.selectMark.val();
            if (value != this._markChange.value || !this._markChange.data) {
                this._markChange.value = value;
                var id = this._elements.selectMark.find('option[value="' + value + '"]').data('id');
                if (id) {
                    var that = this;
                    $.get(this.urls.models, {mark_id: id}).done(function (data) {
                        that._elements.selectModels.parents('label').removeClass('disabled');
                        that._markChange.data = data;
                        if (!callback) {
                            that._clearFields(true, true);
                        }
                        that._elements.selectModels.html(data);
                        var val = that._elements.selectModels.data('value');
                        if (val) {
                            that._elements.selectModels.find('option[value="' + val + '"]').prop('selected', true);
                        }
                        that._elements.selectModels.select2();
                        if (callback) {
                            callback();
                        } else {
                            that._elements.selectModels.select2('open');
                        }

                    }).fail(function () {
                        app.toast.error("Произошла ошибка");
                    });
                } else {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }
                    this._marksLoad({}, this._markChange.bind(this, e, callback));
                }
            }
        },
        _modelsChange: function (e, callback) {
            var value = this._elements.selectModels.val();
            var that = this;
            that._modelsChange.data = that._modelsChange.data || {};
            if (value != this._modelsChange.value || !this._modelsChange.data.series || !this._modelsChange.data.modifications) {
                this._modelsChange.value = value;
                var id = this._elements.selectModels.find('option[value="' + value + '"]').data('id');
                if (id) {
                    $.get(this.urls.serie, {model_id: id}).done(function (data) {
                        that._elements.selectSeries.parents('label').removeClass('disabled');
                        that._modelsChange.data.series = data;
                        if (!callback) {
                            that._clearFields(true, true);
                        }
                        that._elements.selectSeries.html(data);
                        var val = that._elements.selectSeries.data('value');
                        if (val) {
                            that._elements.selectSeries.find('option[value="' + val + '"]').prop('selected', true);
                        }
                        that._seriaSelect();

                        $.get(that.urls.modification, {model_id: id}).done(function (data) {

                            that._elements.selectModification.parents('label').removeClass('disabled');
                            that._modelsChange.data.modifications = data;
                            if (!callback) {
                                that._clearFields(true);
                            }

                            that._modificationData = [];
                            that._parseModificationData($(data));
                            if (callback) {
                                callback();
                            } else {
                                that._elements.year.focus();
                            }
                        }).fail(function () {
                            app.toast.error("Произошла ошибка");
                        });
                    }).fail(function () {
                        app.toast.error("Произошла ошибка");
                    });

                } else {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }
                    this._markChange({}, this._modelsChange.bind(this, e, callback));
                }

            }
        },
        _seriesChange: function (e, callback) {
            var value = this._elements.selectSeries.val();
            var that = this;
            this._modelsChange.data = this._modelsChange.data || {};
            if (value != this._seriesChange.value && this._modelsChange.data.modifications || !this._modelsChange.data.series) {

                var id = this._elements.selectSeries.find('option[value="' + value + '"]').data('id');
                if (id) {
                    this._seriesChange.value = value;
                    this._seriaId = +id;
                    that._elements.selectModification.html(this._modelsChange.data.modifications);
                    var val = that._elements.selectModification.data('value');
                    if (val) {
                        that._elements.selectModification.find('option[value="' + val + '"]').prop('selected', true);
                    }
                    that._modificationSelect();
                    that._elements.selectModification.select2('open');
                } else {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }
                    this._modelsChange({}, this._seriesChange.bind(this, e, callback));
                }
            }
        },
        _modificationChange: function (e, callback) {
            var value = this._elements.selectModification.val();
            if (value != this._modificationChange.value) {
                this._modificationChange.value = value;
                var id = this._elements.selectModification.find('option[value="' + value + '"]').data('id');
                if (id) {
                    var that = this;
                    $.get(this.urls.characteristic, {modification_id: id}).done(function (data) {
                        if (_.isString(data)) {
                            try {
                                data = JSON.parse(data);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        if (_.isObject(data)) {
                            _.each(data, function (item) {
                                var $item = that._elements.container.find('[name="car[' + item.name + ']"]');
                                if ($item.length) {
                                    var _val;
                                    if(item.name == 'engine_type') {
                                        $item.find('option').each(function () {
                                            var text = $(this).text().trim().toLowerCase();
                                           if(text.indexOf(item.value.toLowerCase()) > -1){
                                               _val = $(this).attr('value');
                                           }
                                        });
                                    }

                                    $item.val(_val || item.value);
                                    $item.trigger('change');
                                }
                            });
                        }
                    }).fail(function () {
                        app.toast.error("Произошла ошибка");
                    });
                }
            }

        },
        _modificationDefault: '<option value="">выберите серию</option>',
        _serieDefault: '<option value="">выберите модель</option>',
        _characteristicDefault: '<option value="">выберите модификацию</option>',
        _clearFields: function (modification, serie) {
            if (modification) {
                this._elements.selectModification.html(this._modificationDefault);
                this._modificationSelect();
            }
            if (serie) {
                this._elements.selectSeries.html(this._serieDefault);
                this._seriaSelect();
            }
            _.each(this.characteristicSelectorsMap, function (item) {
                var $item = $(item.selector);
                $item.val('');
                $item.trigger('change');
            });
        }
    };

})();