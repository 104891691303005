/**
 * Created by ursus on 15.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    app.adminUsers  = {
        _selectors: {
            container: '.admin_user-list',
            userItem: '.admin_user-list tbody tr',
            filterAction: '.action-filter',
            filterStatus: '.admin-user-form [name="filter[status]"]',
            search: '.filter-item--search input',
            form: '.admin-user-form'
        },
        _elements: {},
        init: function(){
            if($(this._selectors.container)){
                this._elements = app.elementsParse(this._selectors);
                this._listener();
                var search = this._elements.search.val();
                this._search(search);
            }
        },
        _listener: function(){
            var namespace = 'admin_users';
            var $body = $('body');
            $body.onOne('click', this._selectors.filterAction, namespace, this._clickFilterAction.bind(this));
            $body.onOne(app.ajaxForm.events.success, '', namespace, this.init.bind(this));
            this._elements.search.on('input', this._filter.bind(this));
        },
        _filter: function(e){
            if(this._filter.timeout){
                clearTimeout(this._filter.timeout);
            }
            var that = this;
            this._filter.timeout = setTimeout(function(){
                var val = $(e.currentTarget).val();
                that._search(val);
            }, 100);
        },
        _search: function(val){
            if(val){
                this._elements.userItem.hide();
                this._elements.userItem.find(':Contains("'+val+'")').parents('tr').show();
            }else{
                this._elements.userItem.show();
            }
        },
        /**
         *
         * @param {Event} e
         * @private
         */
        _clickFilterAction: function(e){
            e.preventDefault();
            this._elements.filterAction.parent().removeClass('active');
            $(e.currentTarget).parent().addClass('active');
            this._elements.form.trigger('reset');
            var filter = $(e.currentTarget).data('filterStatus');
            this._elements.filterStatus.val(filter);
            this._elements.form.find('select').trigger('change');
            this._elements.form.submit();
        }
    };
})();