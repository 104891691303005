/**
 * Created by ursus on 13.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";
    app.userAccounts = {
        _selectors: {
            container: '.user-account',
            orgState: '.personal-cabinet-org-state',
            orgGroup: '.organization-inputs',
            oldPassword: '.old_password',
            newPassword: '.new_password',
            newPasswordRepeat: '.new_password_repeat',
            changePassword: '.form-change-password',
            formUpdate: '.form-user-save'
        },
        _elements: {},
        _init: false,
        init: function () {
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
                this._formsInit();
            }
        },
        _listeners: function(){
            var namespace = 'user_account';
            this._elements.orgState.onOne('change','', namespace, this._changeOrgState.bind(this));
            $('body').onOne(app.ajaxForm.events.success, '', namespace, this.init.bind(this) );
        },

        _formsInit: function(){
            app.ajaxForm(this._elements.changePassword, {
                preSubmit: this._validateChangePassword.bind(this),
                successCallback: this._changePasswordSuccess.bind(this),
                failCallback: this._changePasswordFail.bind(this)
            });
            app.ajaxForm(this._elements.formUpdate, {});
            this._updatePreSubmit();
        },
        _updatePreSubmit: function(){
            this._elements.orgGroup.find('input, select, textarea').prop('disabled', true);
            this._elements.orgGroup.filter('.active').find('input, select, textarea').prop('disabled', false);
            return true;
        },
        /**
         *
         * @param {Event} e
         * @private
         */
        _changeOrgState: function(e){
            e.preventDefault();
            var val = $(e.currentTarget).val();
            this._elements.orgGroup.removeClass('active');
            this._elements.orgGroup.filter('[data-value="'+val+'"]').addClass('active');
            this._updatePreSubmit();
        },
        _validateChangePassword: function(){
            var validate = true;
            var oldPassword = this._elements.oldPassword.val();
            var newPassword = this._elements.newPassword.val();
            var newPasswordRepeat = this._elements.newPasswordRepeat.val();
            if(oldPassword && oldPassword == newPassword){
                this._invalidMessage(this._elements.newPassword, 'Пароль не должен совпадать со старым паролем');
                validate = false;
            }
            if(newPassword !== newPasswordRepeat){
                this._invalidMessage(this._elements.newPasswordRepeat, 'пароли не совпадают');
                validate = false;
            }
            return validate;
        },
        _invalidMessage: function($input, message){
            message = message || '';
            var $label = $input.parents('label');
            var $message = $label.find('.label-message');
            if($message.length){
                $message.text(message);
            }else{
                $label.append('<span class="label-message">'+message+'</span>');
            }
            $label.addClass('invalid');
        },
        _changePasswordSuccess: function(){
            console.log(arguments);
        },
        _changePasswordFail: function(){
            console.log(arguments);
        }
    }

    ;
})();