(function(){
    "use strict";
    
    app.userCard = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.personal-cabinet-wr',
            buttons: '.btn',
            formsContaiener: '.form-user-status'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, this._selectors.container);
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
            this._elements.formsContaiener.on('click', this._selectors.buttons, this.formSubmit.bind(this));
        },
        formSubmit: function(e){
            if(e && e.preventDefault){
            e.preventDefault();
            e.currentTarget = $(e.currentTarget).parents('form');

            }else{
                e = {
                    preventDefault: function(){},
                    currentTarget: $(e).parents('form')
                };
            }
            app.formSubmit(e);
        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){
                
            }
        }
    };
})();