/**
 * Created by ursus on 21.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    app.pager = {
        _selectors: {
            container: '.pagination',
            item: '.pagination-item',
        },
        $container: $,
        init: function(){
            this.$container = $(this._selectors.container);
            if(this.$container.length){
                this._pagerInit();
                this._listener();
            }
        },
        _listener: function(){
            var namespace = 'app.pager';
            this.$container.onOne('click', this._selectors.item, namespace, this._page.bind(this));
        },
        _pagerInit: function(){
            var form_selector = this.$container.data('fromSelector');
            var $input;
            var $form;
            var page = this.$container.find('.active').data('page');
            if(form_selector){
                $form = $(form_selector);
                if($form.length){

                    $input = $form.find('[name="page"]');
                    if(!$input.length){
                        $form.append('<input type="hidden" name="page">');
                        $input = $form.find('[name="page"]');
                    }
                    $input.val(page);
                }else{
                    $form = this.$container.parents('form');
                    this.$container.append('<input type="hidden" name="page">');
                    $input = this.$container.find('[name="page"]').val(page);
                }

            }else{
                this.$container.append('<input type="hidden" name="page">');
                $input = this.$container.find('[name="page"]').val(page);
            }
            this.$container.data('input', $input);
            this.$container.data('form', $form);
        },
        _page: function(e){
            e.preventDefault();
            var page = $(e.currentTarget).data('page');
             var $elements = this.$container.data();
            $elements.input.val(page);
            if($elements.form){
            $elements.form.submit();
            }else{
                location.assign($(e.currentTarget).attr('href'));
            }
        }
    };
})();