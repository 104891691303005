(function(){
    "use strict";
    
    app.auction = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.lot-page',
            quick_menu_btn: '.lot-quick-info-btn',
            quick: '.lot-quick-info',
            go_back: '.header-go-back-button',
            print: '.print-button',
            data: '.lot-quick-info-data',
            enterPopup: '.enter-auction',
            enterForm: '.enter-auction form'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
            var namespace = 'lot-info';
            var $body = $('body');
            $body.onOne('click', this._selectors.quick_menu_btn, namespace, this._handleMenuBtnClick.bind(this));
            // this._elements.quick_menu_btn.click(this._handleMenuBtnClick.bind(this));
            $body.on('click', this._selectors.print, this._printPage.bind(this));
            $body.onOne('submit', this._selectors.enterForm, namespace, this.enterAuction.bind(this));
            $body.onOne(app.notification.events.update, '', '', this._dataUpdate.bind(this));
        },

        _printPage: function(e) {
            e.preventDefault();
            window.print();
        },

        _dataUpdate: function(e, data) {
            data = data || {};
            var that = this;
            if(data.auction && this._elements.quick.length){
                var auc_id = that._elements.quick.data('auction-id');
                _.each(data.auction, function(auction){
                    if(auc_id === parseInt(auction.id)){
                        var amount = parseFloat(auction.amount);
                        if(_.isNumber(amount) && !_.isNaN(amount)){
                            that._elements.quick.find('.lot-quick-info-data').data('amount', amount);
                            that._elements.quick.find('.lot-quick-info-val .number').text(app.helpers.priceFormat(amount, 0));
                        }

                    }
                });
            }
        },

        _handleMenuBtnClick: function(e){
            e.preventDefault();
            var $btn = $(e.currentTarget);
            var $item = $btn.parents(this._selectors.quick);
            var $data = $item.find(this._selectors.data);
            var participation = parseInt($data.data('participationPrice'));
            var step = parseInt($data.data('step'));
            var amount = parseInt($data.data('amount'));
            if (!_.isNumber(amount) || !_.isNumber(step)) {
                app.toast.error('Что-то пошло не так... Обратитесь к администратору');
                return;
            }
            var auction_id = $item.attr('data-auction-id');
            if (auction_id) {
                var auction_data = {
                    auction_id: auction_id,
                    amount: amount + step
                };
                if($btn.hasClass('call')){
                    $.post("/buyer/auctions/call", auction_data, function (response) {
                        try {
                            var auction_data = JSON.parse(response);
                            if (!app.helpers.isError(auction_data)) {
                                app.toast.success('Ваша ставка принята');
                            }
                        } catch (e) {
                            app.toast.error('Проблема с поступившей информацией по аукциону');
                        }
                    });
                }
                if($btn.hasClass('join')) {
                    // $.post("/buyer/auctions/enter", auction_data, function (response) {
                    //     try {
                    //         var auction_data = JSON.parse(response);
                    //         if (!app.helpers.isError(auction_data)) {
                    //             app.toast.success('Вы успешно вступили в торги');
                    //             $btn.removeClass('join').addClass('call').text('Сделать ставку');
                    //         }
                    //     } catch (e) {
                    //         app.toast.error('Проблема с поступившей информацией по аукциону');
                    //     }
                    // });
                    var $popup = this._elements.enterPopup.clone();

                    $popup.find('[name=auction_id]').val(auction_id);
                    $popup.find('[name=participation_price]').text(participation);
                    $popup.find('[name=bid]').text(step);
                    app.popups.popupOpen($popup);
                }
            }
            else {
                app.toast.error('id аукциона не найдено');
            }

        },

        /**
         * enterAuction - send entrance info to server, update page according to response and close popup.
         *
         * @param  {Event} e description
         */
        enterAuction: function (e) {
            e.preventDefault();
            var $form = $(e.currentTarget);
            var self = this;
            app.ajaxSubmit($form, {
                successReplace: false, successCallback: function (response) {
                    app.popups.popupsClose();
                    try {
                        if (!app.helpers.isError(response)) {
                            // var auction_data = JSON.parse(response);
                            app.toast.success('Вы успешно вступили в торги');
                            self._elements.quick_menu_btn.removeClass('join').addClass('call').text('Сделать ставку');
                        }

                    }
                    catch (e) {
                        app.toast.error('Проблема с поступившей информацией по аукциону');
                    }
                }
            });
        },

        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){
                
            }
        }
    };
})();