/**
 * Created by ursus on 25.10.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";
    app.adminAction = {
        _selectors: {
            lotAccepted: '.lot-accepted',
            lotDeclined: '.lot-declined',
            auctionStop: '.auction-stop',
            auctionStart: '.auction-start',
            acceptedPopup: '.accept-auction',
        },
        init: function () {
            this._listener();
        },
        _listener: function () {
            var $body = $('body');
            var namespace = "lc_admin_action";
            $body.onOne('click', this._selectors.lotAccepted, namespace, this.lotAccepted.bind(this));
            $body.onOne('click', this._selectors.lotDeclined, namespace, this.lotDeclined.bind(this));
            $body.onOne('click', this._selectors.auctionStop, namespace, this.auctionStop.bind(this));
            $body.onOne('click', this._selectors.auctionStart, namespace, this.auctionStart.bind(this));
        },
        lotAccepted: function (e) {
            e.preventDefault();
            var lot_id = $(e.currentTarget).data('lotId'),
                publish = parseInt($(e.currentTarget).data('publish'));
            if (publish) {
                this.lotAccepted(publish);
            }else{
                if (lot_id) {
                    $.post('/admin/lot/accepted', {lot_id: lot_id}, function (data) {
                        if (!app.helpers.isError(data)) {
                            app.toast.success('Лот подтвержден');
                        }
                    });
                } else {
                    app.toast.error('id лота не найдено');
                }
            }
        },
        lotDeclined: function (e) {
            e.preventDefault();
            var lot_id = $(e.currentTarget).data('lotId');

            if (lot_id) {
                $.post('/admin/lot/decline', {lot_id: lot_id}, function (data) {
                    if (!app.helpers.isError(data)) {
                        app.toast.success('Лот отклонен');
                    }
                });
            } else {
                app.toast.error('id лота не найдено');
            }
        },
        auctionStop: function (e) {
            var auction_id = $(e.currentTarget).data('auctionId');
            if (auction_id) {
                $.post('/admin/auctions/stopped', {auction_id: auction_id}).done(function (data) {
                    if (!app.helpers.isError(data)) {
                        app.toast.success('Аукцион остановлен');
                    }
                }).fail(function (data) {
                    app.toast.error(data);
                });
            }
        },
        auctionStart: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget),
                start_price = $item.data('startPrice'),
                publish = parseInt($item.data('publish')),
                lot_id = $item.data('lotId');
            if (lot_id) {
                var $popup = $(this._selectors.acceptedPopup).clone();
                $popup.find('[name=lot_id]').val(lot_id);
                $popup.find('[name=start_price]').val(start_price);
                if (publish == 1) {
                    $popup.find(this._selectors.popupPublish).show();
                }
                app.popups.popupOpen($popup);
            } else {
                app.toast.error('id лота не найдено');
            }
        }
    };
})();