/**
 * Created by ursus on 19.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";

    app.adminLots = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.admin-new-lots',
            decline: '.auction-remove',
            accepted: '.admin-accepted',
            auctionItem: '.auction-item',
            acceptedForm: '.accept-auction form',
            acceptedPopup: '.accept-auction',
            data: '.auction-item-data',
            popupPublish: '.accept-auction-publish'
        },
        _init: false,
        /***
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
            var $container = $(this._selectors.container);
            var $body = $('body');
            var namespace = 'app.admin.new_lots';
            $container.onOne('click', this._selectors.accepted, namespace, this._accepted.bind(this));
            $container.onOne('click', this._selectors.decline, namespace, this._decline.bind(this));
            $body.onOne('submit', this._selectors.acceptedForm, namespace, this._acceptedSend.bind(this));
        },
        /**
         * Отправляем потверженный лот и его настройки
         * @param {Event} e
         * @private
         */
        _acceptedSend: function(e){
            e.preventDefault();
            var $form = $(e.currentTarget);
            var id = $form.find('[name=lot_id]').val();
            var that = this;
            $form.find('input.price').each(function(){
               var $item =  $(this);
                var val = $item.val();
                val = val.replace(/\s/g, '');
                $item.val(val);
            });
            app.ajaxSubmit($form, {successReplace: false, successCallback: function (data) {
                app.toast.success('Лот подтвержден');
                app.helpersRemoveItem($(that._selectors.auctionItem).filter('[data-lot-id="'+id+'"]'));
                app.popups.popupsClose();
                $('body').trigger(app.auctions.events.items_update);
            }});
        },
        /**
         * Открываем попап потверждения лота
         * @param {Event} e
         * @private
         */
        _accepted: function(e){
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem),
                $data = $item.find(this._selectors.data),
                start_price = $data.data('startPrice'),
                publish = parseInt($data.data('publish')),
                lot_id = $item.data('lotId');
            if(lot_id){
                var $popup = $(this._selectors.acceptedPopup).clone();
                $popup.find('[name=lot_id]').val(lot_id);
                $popup.find('[name=start_price]').parents('label').find('input').val(start_price);
                if(publish == 1){
                    $popup.find(this._selectors.popupPublish).show();
                }
                app.popups.popupOpen($popup);
                $('body').trigger(app.auctions.events.items_update);
                /*$.post('/admin/lot/accepted', {lot_id: lot_id}, function(data){
                if(!app.helpers.isError(data)){
                    app.toast.success('Лот потвержден');
                    app.helpersRemoveItem($item);
                }
            });*/
            }else{
                app.toast.error('id лота не найдено');
            }
        },
        /**
         * Откланяем лот
         * @param {Event} e
         * @private
         */
        _decline: function(e){
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem),
                lot_id = $item.data('lotId');
            if(lot_id){
                $.post('/admin/lot/decline', {lot_id: lot_id}, function(data){
                    if(!app.helpers.isError(data)){
                        app.toast.success('Лот отклонен');
                        app.helpersRemoveItem($item);
                    }
                });
            }else{
                app.toast.error('id лота не найдено');
            }
        }
    };

})();
