(function(){
	"use strict";

	app.popupChat = {
/**
* Селекторы модуля
*/
	_selectors: {
		container: '.popup-chat-wrap',
		chat:'.popup-chat',
    send_button: '.chat-send-button',
    text_field: '.chat-text-field',
    form: '.chat-message-box',
		hide_button: '.popup-chat-hide',
		show_button: '.popup-chat-show'
	},
/**
* jQuery элементы модуля
*/
	_elements: {},
/**
* Флаг инициализации
*/
	_init: false,
/**
* Инициализация модуля
*/
	init: function(){
    var $container = $(this._selectors.container);

		if($container.length && !this._init){
		if($container.length > 1){
			$container.each(function(index, el){
				if(index > 0){
					$(el).remove();
				}
			});
		}
		this._init = true;
		this._elements = app.elementsParse(this._selectors, this._selectors.container);
		this._listeners();
		}
	},
/**
* Постановка обработчиков событий
*/
	_listeners: function(){
		this._elements.send_button.on('click', this.sendMessage.bind(this));
		this._elements.hide_button.on('click', this.hideChat.bind(this));
		this._elements.show_button.on('click', this.showChat.bind(this));
	},

	/**
	 * sendMessage - send message(form) to server and clear text field
	 *
	 * @param  {Event} e
	 */
	sendMessage: function(e) {
		e.preventDefault();
		var self = this;
		var $form = this._elements.form;
		app.ajaxSubmit($form, {successReplace: false, successCallback: function(response) {
			if(!app.helpers.isError(response)) {
				self._elements.text_field.val('');
				app.toast.success('Ваше сообщение отправлено администрации');
			}
		}});
	},

	/**
	 * hideChat - hide popup chat
	 *
	 * @param  {Event} e description
	 */
	hideChat: function(e) {
		e.preventDefault();
		this._elements.chat.addClass('popup-chat-hidden');
		this._elements.show_button.removeClass('hidden');
	},

	/**
	 * showChat - show popup chat
	 *
	 * @param  {Event} e description
	 */
	showChat: function(e) {
		e.preventDefault();
		this._elements.chat.removeClass('popup-chat-hidden');
		$(e.target).addClass('hidden');
	}
	};
})();
