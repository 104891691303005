/**
 * Created by ursus on 14.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";

    app.helpers = {
        isError: function (data, failCallback) {
            failCallback = failCallback || function () {
                };
            var state = false;
            if (_.isString(data) && data.indexOf('error') > -1) {
                try {

                    data = data.trim();
                    data = JSON.parse(data);
                } catch (e) {
                    console.warn(e);
                }
            }
            if (_.isObject(data) && !_.isUndefined(data.error)) {
                state = true;
                app.toast.error(data.message);
                failCallback(data);
                $('body').trigger(app.ajaxForm.events.fail, [data]);
            }
            return state;
        },
        priceFormat: function (price, decimal) {
            decimal = decimal || 0;
            price = price.toFixed(decimal);
            var _price = price.split('.');
            var _decimal = _price[1];
            _price = _price[0];
            var _resultPrice = '';
            var _counter = 1;
            for (var i = _price.length - 1; i >= 0; i--) {
                _resultPrice = _price[i] + _resultPrice;
                if (_counter === 3) {
                    _resultPrice = ' ' + _resultPrice;
                    _counter = 1;
                } else {
                    _counter++;
                }
            }
            if (_decimal) {
                _resultPrice += '.' + _decimal;
            }
            return _resultPrice.trim();
        }
    };
})();