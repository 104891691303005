(function(){
    "use strict";

    app.sellerLotEdit = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.seller_lot_edit',
            stepItem: '.steps-item',
            form: 'form',
            savePopup: '.popup-container .seller-save-popup',
            actionDontSave: '.seller-action-dont-save',
            actionCancel: '.seller-action-cancel',
            actionSave: '.seller-action-save'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Флаг изменения значения одного из полей
         */
        _changeFlag: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, this._selectors.container);
                this._listeners();
            }
        },

        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
          this._elements.container.on('change', 'input, select, textarea', this._change.bind(this));
            this._elements.container.on('submit', 'form', this._formSubmit.bind(this));
            $('body').on('click', this._selectors.stepItem, this._beforeChangeStep.bind(this));
            var $body =  $('body');
            $body.onOne('click', this._selectors.actionSave, 'sellerLotEdit', this._save.bind(this));
            $body.onOne('click', this._selectors.actionDontSave, 'sellerLotEdit', this._dontSave.bind(this));
            $body.onOne('click', this._selectors.actionCancel, 'sellerLotEdit', this._cancel.bind(this));
            window.onbeforeunload  = this._beforeUnload.bind(this);
        },
        _dontSave: function(){
            this._changeFlag = false;
            location.assign(this._link);
        },
        _save: function(){
            $('[name="redirect_url"]').val(this._link);
            this._changeFlag = false;
            this._elements.form.submit();
        },
        _cancel: function(){
            app.popups.popupsClose();
        },
        _link: '',
        _beforeChangeStep: function(e){
            if(this._changeFlag){
                e.preventDefault();
                var link = $(e.currentTarget).attr('href');
                this._link = link;
                var that = this;
                this._savePopup();


                // if(confirm("У вас есть несохраненные данные. Хотите их сохранить?")){
                //     app.ajaxSubmit(this._elements.form, {successCallback: function () {
                //         that._changeFlag = false;
                //         location.assign(link);
                //
                //     }, failCallback: function(){
                //         alert("Что то пошло не так...");
                //     }});
                // }else{
                //     that._changeFlag = false;
                //     location.assign(link);
                // }
            }
        },

        _savePopup: function(){
            var $popup = $(this._selectors.savePopup).clone();
            app.popups.popupOpen($popup);
        },
        _formSubmit: function(e){
            app.formStaticSubmit(e);
            this._changeFlag = false;
        },
        /**
         * Отслеживаем переход на другую страницу
         * @param {Event} e
         * @return {string}
         * @private
         */
        _beforeUnload: function(e){
            console.log(e);
            if(this._changeFlag){
                return "Вы не сохранили данные. Вы точно хотите перейти на другую страницу?";
            }
        },
        /**
         * Отслеживаем изменения поля
         * @private
         */
        _change: function(){
            this._changeFlag = true;
        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();