/**
 * Created by ursus on 29.07.2016.
 */

/* globals _ */
(function(){
    "use strict";
    app.sort = {
      _selectors: {
          item: '.sort-arrow',
          input: '.sort-input'
      },
      _template: _.template('<input type="hidden" value="<%=value%>" name="<%=name%>" class="sort-input" />'),
      init: function(){
          this._listener();
      },
       _listener: function(){
             $('body').onOne('click', this._selectors.item, 'sort', this.sorting.bind(this));
       },
       sorting: function(e){
           e.preventDefault();
           var $item = $(e.currentTarget),
                formSelector = $item.data('formSelector'),
               name = $item.data('name'),
               value = $item.data('value'),
               $form = $(formSelector);
           if($form.length){
               if(!name){
                   alert("app.sort:sorting name is not defined");
               }
               $form.find(this._selectors.input).remove();
               $form.append(this._template({name: name, value: value}));
               $form.submit();
           }

       }
    };
})();
