(function(){
    "use strict";
    app.file = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: 'body',
            btnDelete: '.form-item-file-img-delete',
            btnDeleteNew: '.form-item-file-img-delete.new',
            img: '.form-item-file-img'
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $container);
                this._listener();
            }
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){
            this._elements.container.on('click', this._selectors.btnDeleteNew, this._deleteFile.bind(this));
            this._elements.btnDelete.click(this._deleteFile.bind(this));
            this._elements.container.on('click', this._selectors.img, this._imgPopup.bind(this));

        },
        _imgPopup: function(e){

            var $item = $(e.currentTarget);
            if($item.is('a')) {
                var src = $item.css('background-image');
                if (src && src.indexOf('base64') > -1) {
                    e.preventDefault();
                    app.popups.popupOpen($item.clone());
                }
            }
        },
        _deleteNewFile: function(e){
            e.preventDefault();
            e.stopPropagation();
            var $img = $(e.currentTarget).parents(this._selectors.img);
        },
        _deleteFile: function(e){
            e.preventDefault();
            e.stopPropagation();
            var that = this;
            var $item = $(e.currentTarget);
            var id = $item.data('id');

            if(id){
                $.post('/file/delete', {id: id}).done(function(response){

                    if(!app.helpers.isError(response)){
                        $item.parents(that._selectors.img).remove();
                    }
                }).fail(function(error){
                    app.toast.error(error);
                });
            }
        },
        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();