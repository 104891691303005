(function(){
    "use strict";
    app.buyerPayment = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: 'body',
            popup: '.buyer-payment-popup',
            open_popup: '.open_payment_popup',
            payment_form: '.buyer-payment-popup form'
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $container);
                this._listener();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listener: function(){
            var namespace = 'app.buyer.payment';
            this._elements.container.on('click', this._selectors.open_popup, this._openPaymentPopup.bind(this));
            this._elements.container.onOne('submit', this._selectors.payment_form, namespace, this._handlePaymentRequest.bind(this));
        },

        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){},

        _openPaymentPopup: function(e) {
            e.preventDefault();
            this._elements.popup.find('.first-variant').find('input').trigger('change');
            var $popup = this._elements.popup.clone();

            app.popups.popupOpen($popup);
        },

        _handlePaymentRequest: function(e) {
            e.preventDefault();
            var $form = $(e.currentTarget);

            app.ajaxSubmit($form, {
                successReplace: false, successCallback: function (response) {
                    app.popups.popupsClose();
                    try {
                        if (!app.helpers.isError(response)) {
                            var response_data = JSON.parse(response);
                            var payment_url = response_data.formUrl;
                            if(payment_url) {
                                location.assign(payment_url);
                            }

                        }

                    }
                    catch (e) {
                        app.toast.error('Не получилось отправить заявку на пополнение баланса');
                    }
                }
            });
        }
    };
})();