/**
 * Created by ursus on 29.07.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    
    app.user = {
        _selectors: {
            buttonUpdateState: '.user-button-update'
        },
        init: function(){
            this._listener();
        },
        _listener: function(){
         $('body').onOne("click", this._selectors.buttonUpdateState, 'user', this._userRowUpdate.bind(this));   
        },
        _userRowUpdate: function(e){
            e.preventDefault();
            var $item = $(e.currentTarget),
                id = $item.data('id'),
                type = $item.data('id');
                this.update({id: id, type:  type}).success(function(data){
                    var $prev = $item.prev();
                    if($prev.length){
                        $item.remove();
                        $prev.after(data);
                    }else{
                        var $parent = $item.parent();
                        $item.remove();
                        $parent.prepend(data);
                    }
                }).fail(function(){
                    app.messagebox.alert('Что-то пошло не так');
                });
        },
        update: function(object){
            if(!object.id){
                app.error("app.user:_userRowUpdate = id is not define");
             
            }
            return $.post('/user/update', {user: object});
        }
    };
})();