/**
 * Created by ursus on 29.07.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    app.error = function(text){
        console.error(text);
    };
})();