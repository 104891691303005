(function () {
    "use strict";

    app.buyerAuctions = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.auction-list',
            auctionItem: '.auction-item',
            enter: '.auction-button-enter',
            bid: '.auction-button-bid',
            leave: '.auction-button-leave',
            data: '.auction-item-data',
            enterPopup: '.enter-auction',
            enterForm: '.enter-auction form',
            amount: '.auction-current-amount .number',
            quick_info: '.lot-quick-info',
            status_block: '.auction-item-status-block'
        },

        _elements: {},

        _init: false,
        /**
         * Инициализация модуля
         */
        init: function () {
            if ($(this._selectors.container).length && !this._init) {
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         */
        _listeners: function () {
            var $body = $('body');
            var namespace = 'app.buyer.auctions';
            this._elements.enter.on('click', this.openLotPopup.bind(this));
            $body.onOne('submit', this._selectors.enterForm, namespace, this.enterAuction.bind(this));
            this._elements.bid.on('click', this.placeBid.bind(this));
            this._elements.leave.on('click', this.leaveAuction.bind(this));
            $body.onOne(app.notification.events.update, '', '', this._dataUpdate.bind(this));
        },
        _dataUpdate: function (e, data) {
            data = data || {};
            var that = this;
            if(data.auction){
            _.each(data.auction, function(auction){
                var $auction = that._elements.auctionItem.filter('[data-auction-id="'+auction.id+'"]');
                if($auction.length){
                    var amount = parseFloat(auction.amount);
                    if(_.isNumber(amount) && !_.isNaN(amount)){
                        $auction.find(that._selectors.data).data('amount', amount);
                        $auction.find(that._selectors.amount).text(app.helpers.priceFormat(amount, 0));
                    }

                    /**
                     * если пользователь - покупатель то обновляем статус в блоке статуса ставки
                     */
                    var $status_block = $auction.find(that._selectors.status_block);
                    if($status_block.length) {
                        if($status_block.data('participation')) {
                            var user_id = that._elements.container.data('userId').toString();

                            if(user_id === auction.user_id) {
                                $status_block.removeClass('losing').addClass('leading');
                            } else {
                                $status_block.removeClass('leading').addClass('losing');
                            }
                        } else {
                            $status_block.removeClass('losing').removeClass('leading');
                        }
                    }
                }
            });
            }
        },

        /**
         * openLotPopup - open entrance menu for picked auction
         *
         * @param  {Event} e description
         */
        openLotPopup: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem);
            var auction_id = $item.attr('data-auction-id');
            if (auction_id) {
                var $data = $item.find(this._elements.data);
                var participation_price = $data.attr('data-participation-price'),
                    bid_step = $data.attr('data-step');
                var $popup = $(this._elements.enterPopup).clone();

                $popup.find('[name=auction_id]').val(auction_id);
                $popup.find('[name=participation_price]').text(participation_price);
                $popup.find('[name=bid]').text(bid_step);
                app.popups.popupOpen($popup);
            } else {
                app.toast.error('id аукциона не найдено');
            }
        },


        /**
         * enterAuction - send entrance info to server, update page according to response and close popup.
         *
         * @param  {Event} e description
         */
        enterAuction: function (e) {
            e.preventDefault();
            var $form = $(e.currentTarget);
            var self = this;
            app.ajaxSubmit($form, {
                successReplace: false, successCallback: function (response) {
                    app.popups.popupsClose();
                    try {
                        if (!app.helpers.isError(response)) {
                            var auction_data = JSON.parse(response);
                            app.toast.success('Вы успешно вступили в торги');
                            var $auction = $('[data-auction-id=' + auction_data.id + ']');
                            $auction.find('.auction-current-amount .number').text(auction_data.amount);
                            $auction.find(self._selectors.enter).addClass('auction-button-hidden');
                            $auction.find(self._selectors.leave).removeClass('auction-button-hidden');
                            $auction.find(self._selectors.bid).removeClass('auction-button-inactive');
                            $auction.find(self._selectors.status_block).data('participation', true);
                        }

                    }
                    catch (e) {
                        app.toast.error('Проблема с поступившей информацией по аукциону');
                    }
                }
            });
        },

        /**
         * placeBid - send bid request to server
         *
         * @param  {Event} e description
         */
        placeBid: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem);
            var $data = $item.find(this._selectors.data);
            var step = parseInt($data.data('step'));
            var amount = parseInt($data.data('amount'));
            if (!_.isNumber(amount) || !_.isNumber(step)) {
                app.toast.error('Что-то пошло не так... Обратитесь к администратору');
                return;
            }
            var auction_id = $item.attr('data-auction-id');
            if (auction_id) {
                var auction_data = {
                    auction_id: auction_id,
                    amount: amount + step
                };
                $.post("/buyer/auctions/call", auction_data, function (response) {
                    try {
                        var auction_data = JSON.parse(response);
                        if (!app.helpers.isError(auction_data)) {
                            app.toast.success('Ваша ставка принята');
                        }
                    } catch (e) {
                        app.toast.error('Проблема с поступившей информацией по аукциону');
                    }
                });
            }
            else {
                app.toast.error('id аукциона не найдено');
            }
        },

        /**
         * leaveAuction - inform server about leaving auction
         *
         * @param  {Event} e description
         * @return {type}   description
         */
        leaveAuction: function (e) {
            var self = this;
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem);
            var auction_id = $item.attr('data-auction-id');
            if (auction_id) {
                var auction_data = {
                    auction_id: auction_id
                };
                $.post("/buyer/auctions/leave", auction_data, function (response) {
                    try {
                        var auction_data = JSON.parse(response);
                        if (!app.helpers.isError(auction_data)) {
                            var $auction = $('[data-auction-id=' + auction_data.id + ']');
                            $auction.find(self._selectors.enter).removeClass('auction-button-hidden');
                            $auction.find(self._selectors.leave).addClass('auction-button-hidden');
                            $auction.find(self._selectors.bid).addClass('auction-button-inactive');
                            $auction.find(self._selectors.status_block).data('participation', false);
                            app.toast.success('Вы покинули торги');
                        }
                    } catch (e) {
                        app.toast.error('Проблема с поступившей информацией по аукциону');
                    }
                });
            }
        }
    };
})();
