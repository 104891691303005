(function(){
    "use strict";

    app.adminInvite = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.admin-invite',
            type: '[name="invite[type]"]',
            organization: '.organization'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
            this._elements.type.change(this._changeType.bind(this));
        },
        /**
         * Обрабатываема смену типа пользователя и выключаем поля организации для администратора
         * @param {Event} e
         * @private
         */
        _changeType: function(e){
            var type = this._elements.type.filter(':checked').val();
            if(type == '1'){
                this._elements.organization.hide(300);
                this._elements.organization.find('input, select, textarea').prop('disabled', true);
            }else{
                this._elements.organization.show(300);
                this._elements.organization.find('input, select, textarea').prop('disabled', false);
            }
        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();