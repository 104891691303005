/**
 * Created by ursus on 26.07.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";
    var _default = function () {
        return true;
    };
    var _config = {
        successCallback: _default,
        failCallback: _default,
        preSubmit: _default,
        successReplace: true
    };


    /**
     *
     * @param {jQuery} $form - форма которую мы иницилизируем
     * @param  {Object} [config] - конфиг
     * @param  {Function} [config.successCallback]
     * @param  {Function} [config.failCallback]
     * @param  {Function} [config.preSubmit]
     * @param  {Boolean} [config.successReplace]
     *
     */
    app.ajaxForm = function ($form, config) {
        var formConfig = $.extend(true, {}, _config, config);
        $form = $($form);
        $form.ajaxForm({
            beforeSubmit: function (arr, $form, options) {
                var state = app.validator.formValidate(arr, $form);

                if (state) {
                    state = formConfig.preSubmit(arr, $form, options);
                }
                if(!state){
                    var pos = $('.invalid').first().position();
                    var top = pos && pos.top ? pos.top : 0;
                    if(top){
                        top -= $('.header').outerHeight();
                        $(window).scrollTo(top, 400);
                    }
                }
                $form.toggleClass('preload', state);
                return state;
            },
            success: function (response, statusText, xhr, form) {

                if (!app.helpers.isError(response)) {
                    var $form = $(form);
                    if (formConfig.successReplace) {
                        var selector = $form.data('successSelector');
                        if (selector) {
                            $(selector).html(response);
                        } else {
                            $form.parent().html(response);
                        }
                    }
                    formConfig.successCallback(response, statusText, xhr, form);
                    $('body').trigger(app.ajaxForm.events.success, [response, statusText, xhr, form]);
                }
            },
            fail: function (response, statusText, xhr, form) {
                formConfig.failCallback(response, statusText, xhr, form);
            }
        });
    };

    app.ajaxSubmit = function ($form, config) {
        var formConfig = $.extend(true, {}, _config, config);
        $form = $($form);
        $form.ajaxSubmit({
            beforeSubmit: function (arr, $form, options) {
                var state = app.validator.formValidate(arr, $form);
                $form.toggleClass('preload', state);
                if (state) {
                    state = formConfig.preSubmit(arr, $form, options);
                }
                if(!state){
                    var pos = $('.invalid').first().position();
                    var top = pos && pos.top ? pos.top : 0;
                    if(top){
                        top -= $('.header').outerHeight();
                        $(window).scrollTo(top, 400);
                    }
                }
                return state;
            },
            success: function (response, statusText, xhr, form) {

                if (!app.helpers.isError(response)) {
                    var $form = $(form);
                    if (formConfig.successReplace) {
                        var selector = $form.data('successSelector');
                        if (selector) {
                            $(selector).html(response);
                        } else {
                            $form.parent().html(response);
                        }
                    }
                    formConfig.successCallback(response, statusText, xhr, form);
                    $('body').trigger(app.ajaxForm.events.success, [response, statusText, xhr, form]);
                }
            },
            fail: function (response, statusText, xhr, form) {
                formConfig.failCallback(response, statusText, xhr, form);
            }
        });

    };
    app.ajaxForm.events = {
        success: 'app.ajaxForm.events',
        fail: 'app.ajaxForm.fail',
    };

})();