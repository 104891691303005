/**
 * Created by ursus on 21.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";

    app.activeAuctions = {
        _selectors: {
            container: '.active-auctions',
            timerItem: '.auction-date-end',
            auctionItem: '.auction-item, .lot-quick-info'
        },
        _elements: {},
        _timerInterval: 0,
        _time: 0,
        init: function () {
            this._time = parseInt($('body').data('time'));
            this._elements = app.elementsParse(this._selectors);
            this._timersInit();
            this._listeners();
        },
        _timersInit: function () {
            var that = this;
            this._elements.timerItem.each(function () {
                var $item = $(this),
                    dateStr = $item.data('date');
                if (dateStr) {
                    var date = that._dateParse(dateStr);
                    if (date - (new Date(that._time * 1000)) < 0) {
                        $item.text("Завершен");
                    } else {
                        // $item.data('Date', date);
                    }
                }
            });
            if (this._timerInterval) {
                clearTimeout(this._timerInterval);
            }
            if (this._elements.timerItem.length) {
                this._timerInterval = setInterval(this._timerUpdate.bind(this), 1000);
            }
        },
        _dateParse: function (date_str) {
            var date = date_str.split(' ');
            var dateArr = date[0].split('-');
            var timeArr = date[1].split(':');
            return new Date(+dateArr[0], (+dateArr[1] - 1), +dateArr[2], +timeArr[0], +timeArr[1], +timeArr[2]);
        },
        _listeners: function () {
            var $body = $('body');
            var namespace = 'active_auctions';
            $body.onOne(app.notification.events.update, '', namespace, this._timeUpdate.bind(this));
        },
        _timeUpdate: function (e, data) {
            if (data && data.auction) {
                var that = this;
                _.each(data.auction, function (auction_item) {
                    var $item = that._elements.auctionItem.filter('[data-auction-id="' + auction_item.id + '"]');
                    $item.removeClass('disabled');
                    var _timeout = $item.data('timeout');
                    if(_timeout)
                        clearTimeout(_timeout);

                    var $timer = $item.find(that._selectors.timerItem);
                    $timer.data('Date', auction_item.end_time);

                    if (auction_item.end_time > 0 && auction_item.end_time * 1000 < 300000 ) {
                        $timer.addClass('time-running-out');
                    } else {
                        $timer.removeClass('time-running-out');
                    }
                    _timeout = setTimeout(function(){
                        $item.addClass('disabled');
                    }, 10000);
                    $item.data('timeout', _timeout);
                });
            }

        },
        _timerUpdate: function () {
            this._time++;
            var self = this;
            var now = new Date(this._time * 1000);
            this._elements.timerItem.each(function () {
                var $item = $(this),
                    date = $item.data('Date');
                if (date) {
                    var definition = date * 1000;

                    if (definition > 0) {
                        var defDate = new Date(definition);
                        var _date = defDate.getDate() - 1,
                            hour = defDate.getUTCHours(),
                            minutes = defDate.getMinutes(),
                            seconds = defDate.getSeconds();
                        hour = hour > 9 ? hour : '0' + hour;
                        minutes = minutes > 9 ? minutes : '0' + minutes;
                        seconds = seconds > 9 ? seconds : '0' + seconds;
                        var dateStr = hour + ':' + minutes + ':' + seconds;
                        if (_date) {
                            var daySuffix = self._getDaySuffix(_date) || ' ';
                            dateStr = _date + daySuffix + dateStr;
                        }
                        $item.text(dateStr);
                    } else {
                        $item.text("Завершен");
                    }
                }
            });
        },

        /**
         * getDaySuffix - выбирает правильное склонение слова "день"
         *
         * @param  {Number} num кол-во дней.
         * @return {String}    слово "день" в нужном склонении.
         */
        _getDaySuffix: function (num) {
            if (num) {
                if (num >= 0 && num <= 20) {
                    if (num === 1) {
                        return ' день ';
                    } else if (num >= 2 && num <= 4) {
                        return ' дня ';
                    } else {
                        return ' дней ';
                    }
                } else {
                    var lastDigit = num % 10;
                    if (lastDigit === 1) {
                        return ' день ';
                    } else if (lastDigit >= 2 && lastDigit <= 4) {
                        return ' дня ';
                    } else {
                        return ' дней ';
                    }
                }
            } else {
                return '';
            }

        }
    };

})();
