/**
 * Created by ursus on 23.08.2016.
 */

/* globals _ */
/* globals cms */

(function(){
    "use strict";




    var resetKey = "X";
    app.carInsperction = {
        _init: false,
        _selectors: {
            container: '.car-inspection',
            pointItemContent: '.car-inspection-point-content',
            pointItem: '.car-inspection-item-point',
            pointQuestion: '.car-inspection-point-content .point-question',
            pointOk: '.car-inspection-point-content .point-ok',
            pointDanger: '.car-inspection-point-content .point-danger',
            pointListItem: '.car-inspection-point-content .point-list-item',
            pointControlItem: '.point-control-item',
            pointListContent: '.point-list-content',
            submit: '.point-list-submit',
            pointItemBuyerMode: '.view-only',
            all_ok_button: '.all-ok-button'
        },
        _elements: {},
        _lastPositionTop: 0,
        init: function(){
            if($(this._selectors).length && !this._init){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                //this._pointInit();
                this._listener();
                this._checkIfStatusOk();
                this._setDamageState();
            }
        },
        _pointInit: function(){
            var that = this;
            this._elements.pointItem.each(function(){
                $(this).html(that._elements.pointItemContent.clone());
            });
        },
        _listener: function () {
            this._elements.pointQuestion.click(this._pointQuestionClick.bind(this));
            this._elements.pointOk.click(this._pointOkClick.bind(this));
            this._elements.pointDanger.click(this._pointDangerClick.bind(this));
            this._elements.pointListItem.click(this._pointListItemClick.bind(this));
            this._elements.pointItem.hover(this._toggleTile.bind(this, true), this._toggleTile.bind(this, false));
            this._elements.pointControlItem.on(app.scale.events.active, this._pointControlScaleChange.bind(this));
            this._elements.pointListContent.on('change', 'input[type="checkbox"]', this._checkboxChange.bind(this));
            this._elements.submit.click(this._pointListItemClick.bind(this));
            this._elements.pointItemBuyerMode.click(this._toggleDamageInfo.bind(this));
            this._elements.all_ok_button.click(this._markAllOk.bind(this));
        },

        /**
         *
         * @param {Event} e Event Object
         * @private
         */
        _checkboxChange: function(e){
            var $checkbox = $(e.currentTarget),
                key = $checkbox.data('key');
            var $content = $checkbox.parents(this._selectors.pointListContent);
            if(key === resetKey){

                var state = $checkbox.prop('checked');
                $checkbox.parents(this._selectors.pointListContent).toggleClass('set-values', state);
                $content.find('input[type="checkbox"]').not('[data-key="'+resetKey+'"]').each(function(){
                    if(state){
                        app.forms.checkboxChange({currentTarget: this}, false);
                        $(this).parents('label.checkbox').addClass('disabled');
                    }else{
                        $(this).parents('label.checkbox').removeClass('disabled');
                    }
                });
            }
            this._setValues($content);
        },
        /**
         *
         *
         * @param  $content
         * @private
         */
        _setValues: function($content){
            var $checkedElements = $content.find('input[type=checkbox]:checked');
            var statePhoto = $checkedElements.not('[data-key="'+resetKey+'"]').length;
            var state = $checkedElements.length;
            $content.toggleClass('damage', !!statePhoto);
            $content.toggleClass('set-value', !!state);
        },
        /**
         *
         * @param {Event} e Event Object
         * @param {string} value Значение выбранной шкалы
         * @private
         */
        _pointControlScaleChange: function(e, value){
            var $item = $(e.currentTarget);
            if(value){
                $item.find("input[type=checkbox]").val(value);
            }
        },
        _pointItemClick: function(e){
            e.preventDefault();
            var $el = $(e.currentTarget);
            $el.toggleClass('active');
        },
        _pointQuestionClick: function (e) {
            e.preventDefault();
            var $parent = $(e.currentTarget).parents(this._selectors.pointItem);
            this._elements.pointItem.removeClass('active list');
            $parent.addClass('active');

        },
        _pointOkClick: function(e){
          e.preventDefault();
           var $parent = $(e.currentTarget).parents(this._selectors.pointItem);
           $parent.removeClass('active danger list').addClass('ok');
           $parent.find('input[type!="file"]').val('2');
        },
        _pointDangerClick: function(e){
            e.preventDefault();
            var $parent = $(e.currentTarget).parents(this._selectors.pointItem);
            this._lastPositionTop = $(window).scrollTop();
            $parent.removeClass('ok').toggleClass('list');
            $parent.find('input[type="hidden"]').val('0');
            var $checkboxes = $parent.find('input[type="checkbox"]');
            $checkboxes.each(function() {
              var key = $(this).data('key');
              if($(this).val() === "2") {
                $(this).val(key);
              }
            });

        },
        _pointListItemClick: function(e){
            e.preventDefault();
            var $el = $(e.currentTarget);
            var $parent = $el.parents(this._selectors.pointItem);

            $parent.removeClass('active ok list').addClass('danger');
            $(window).scrollTo(this._lastPositionTop, 300);

        },
        _toggleTile: function( state, e){
            e.preventDefault();
            if(this._toggleTile.timeout){
                clearTimeout(this._toggleTile.timeout);
            }
            var that = this;
            this._toggleTile.timeout = setTimeout(function(){
                that._elements.pointItem.removeClass('title');
                $(e.currentTarget).toggleClass('title', state);
            }, 300);
        },

        /**
         * _checkIfStatusOk - if node's status is "ok" set it's hidden checkbox's value to 2;
         */
        _checkIfStatusOk: function() {
            this._elements.container.find('.ok').find('input[type="hidden"]').val('2');
        },


        /**
         * _setDamageState - for each damaged element get damage value and visualize it
         *
         */
        _setDamageState: function() {
          var $damagedElements = this._elements.container.find('.car-inspection-item-point.danger').find('.point-control-item');
          $damagedElements.each(function() {
              var $checkedBoxes = $(this).find('input[type=checkbox]:checked');
              $checkedBoxes.each(function() {
                  var param = $(this).val();
                  if (param && param !== "0" && param !== "1" && param !== "2") {
                      var $item = $(this).parents('.point-control-item').find(".scale-item[data-key=" + param + "]");
                      $item.addClass('active');
                      $item.prevAll().addClass('active');
                  }
              });
          });
        },
        _toggleDamageInfo: function(e) {
                var state = $(e.currentTarget).hasClass('list');
                this._elements.pointItem.removeClass('list');
                $(e.currentTarget).toggleClass('list', !state);
        },

        /**
         * _markAllOk - mark all unchecked elements as ok
         *
         * @param  {Event} e description
         */
        _markAllOk: function(e) {
          e.preventDefault();
          var button = e.currentTarget;
          var $points = $(button).closest('.car-inspection-item').find(this._selectors.pointItem);
          $points.each(function() {
            if(!($(this).hasClass('ok')) && !($(this).hasClass('danger'))) {
              $(this).addClass('ok');
              $(this).find('input[type!="file"]').val('2');
            };
          })
        }
    };

})();
