/**
 * Created by ursus on 29.07.2016.
 */

/* globals _ */
/* globals cms */

(function () {
    "use strict";

    app.messagebox = {
        alert: function(text, callback){
           alert(text);
            if(_.isFunction(callback)){
                callback();
            }
        },
        confirm: function(text, callback){
            var state = confirm(text);
            if(_.isFunction(callback)){
                callback(state);
            }
        },
        prompt: function(text, callback){
            var state = prompt(text);
            if(_.isFunction(callback)){
                callback(state);
            }
        }
    };
})();