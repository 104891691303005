/**
 * Created by ursus on 20.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";

    app.sellerlotAdd = {
        /**
         * Селекторы инпутов из которых складывается тайтл лота
         */
        _inputs: [
            '[name="car[id_car_mark]"]',
            '[name="car[id_car_model]"]',
            '[name="car[id_car_modification]"]'
        ],
        /**
         * Сулукторы модуля
         */
        _selectors: {
            container: '.seller-lot-add',
            form: '.seller-lot-add',
            title: '[name="info"], [name="lot[info]"]'
        },
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function () {
            if ($(this._selectors.container)) {
                this._listeners();
            }
        },
        /**
         * Постновка обработчиков событий
         * @private
         */
        _listeners: function () {
            $(this._selectors.form).submit(this._formSubmit.bind(this));
        },
        /**
         * Выполняем операции перед отправкой формы
         * @param {Event} e
         * @private
         */
        _formSubmit: function (e) {
            var $form = $(e.currentTarget);
            if (!$form.hasClass('module-validate')) {
                e.preventDefault();
                this._lotTitle();
                $form.addClass('module-validate');
                $form.submit();
            }
        },
        /**
         * Собираем и сохроняем title лота
         * @private
         */
        _lotTitle: function () {
            var title = '';
            _.each(this._inputs, function (selector) {
                var $select = $(selector);
                var val = $select.val();
                if (val) {
                    var _title = $select.find('[value="' + val + '"]').first().text();
                    title += _title + ' ';
                }
            });
            $(this._selectors.title).val(title);
        }
    };
})();