/**
 * Created by ursus on 14.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";

    app.SelectAtoload = function(url, paramName ){
        this.url = url;
        this.paramName =  paramName || '';
        this.init();
    };

    app.SelectAtoload.prototype = {
        _selectors: {
            item: '.select-load',
        },
        init: function(){
            this._listener();
            this._loadInit();
        },
        _listener: function(){
            var $body = $('body');
            $body.on('focus select2:open', this._selectors.item, this._load.bind(this));
            $body.on('change', this._selectors.item, this._change.bind(this));
        },
        _loadInit: function(){
            $('[data-parent-item]').each(function(){
                var $item = $(this);
                var parentItems = $item.data('parentItem');
                var parentVals = true;
                _.each(parentItems, function(parentItem){
                    if(!$('[name="' + parentItem + '"]').val()){
                        parentVals = false;
                    }
                });
                if(parentVals){
                    $item.data('loadEnd', false);
                }
            });
        },
        _change: function(e, state){
            if(!state){
                var $item = $(e.currentTarget);
                var name = $item.attr('name');
                var $child = $(this._selectors.item).filter('[data-parent-item*="'+name+'"]');
                if($child.length){
                    $child.data('loadEnd', false);
                    var alternate_text = $child.data('alternateText');
                    if(alternate_text){
                        $child.find('[value=""]').text(alternate_text);
                        $child.select2({ 'locale': 'ru' });
                    }

                }
            }
        },
        _load: function(e){

            var $item = $(e.currentTarget);
            var data = $item.data();
            var queryObject = {};
            if(!data.loadEnd) {
                $item.html('<option value="" class="preloader"> </option>');
               /* $item.select2();
                $item.select2('open');*/
                if (!data.parentItem) {
                    if (data.method) {
                        queryObject[this.paramName] = data.method;
                    }
                    $.get(this.url, queryObject, this._loadSuccess.bind(this, $item));
                }else{
                    _.each(data.parentItem, function(item, key){
                        queryObject[data.param[key]] = $('[name="' + data.parentItem + '"]').val();
                    });

                   if(!_.isEmpty(queryObject)){
                    if(data.method){
                        queryObject[this.paramName] = data.method;
                    }
                    $.get(this.url, queryObject, this._loadSuccess.bind(this, $item));
                   }

                }
            }
        },
        _loadSuccess: function($item, data){
            if(!app.helpers.isError(data)){
                $item.html(data);
                var value = $item.data('value');
                if(value){
                    $item.val(value);
                }

                $item.data('loadEnd', true);
                $item.select2();
                $item.select2('open');
                $('body').trigger(this.events.load, [$item, $item.attr('name')]);
            }
        },

        _loadFail: function(data){
            app.toast.error("Ошибка загрузки");
        },
        events: {
            load: 'select.autoload.success',
            change: 'select.autoload.change'
        }
    };

})();
