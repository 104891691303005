/**
 * Created by ursus on 22.08.2016.
 */

/* globals _ */
/* globals cms */

(function(){
    "use strict";

    app.tabs = {
        _selectors: {
            page: '.tabs-pages-item',
            control: '.tabs-controls-item',
            container: '.tabs',
            // organizationTab: '.type-of-organization .form-group-col-3'
        },
        _init: false,
        init: function(){
            if(!this._init){
                this._init = true;
                this._listener();
            }
        },
        _listener: function(){
            $('body').on('click', this._selectors.control, this.change.bind(this));
            // $('body').on('click', this._selectors.organizationTab, this.change.bind(this));
        },
        /**
         *
         * @param {Event} e
         */
        change: function(e){
            var $control = $(e.currentTarget);
            var $parent = $control.parents(this._selectors.container);
            var $controls = $parent.find(this._selectors.control);
            var $pages = $parent.find(this._selectors.page);
            var index = $controls.index($control);
            $pages.removeClass('active');
            $controls.removeClass('active');
            $control.addClass('active');
            $pages.eq(index).addClass('active');
        }
    };

})();



