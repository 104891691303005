(function(){
    "use strict";
    app.sellerOutPopup = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: '',
            action: ''
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $container);
                this._listener();
            }
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){

        },
        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();