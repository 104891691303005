/**
 * Created by ursus on 22.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    var _config ={
        timeout: 1000,
        class: 'disabled'
    };
   app.helpersRemoveItem = function($item, config){
       config = config || {};
       var itemConfig = $.extend(true, config, _config);
       $item.addClass(itemConfig.class);
       setTimeout(function(){
           $item.remove();
       }, itemConfig.timeout);
   };


})();