/**
 * Created by ursus on 19.09.2016.
 */

/* globals _ */
/* globals cms */

(function(){
    "use strict";


    app.logs = {
        /**
         * Данные логов
         */
        data: window.logs,
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.admin-logs',
            infoItem: '.log-item-info',
            resultItem: '.log-result-wrap',
            resultItemContainer: '.log-result-container',
            params:".log-params",
            result:'.log-result',
            jsonToggle: '.json-toggle'
        },
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
          if($(this._selectors.container).length){
              this._init = true;
              this._listener();

          }
        },
        /**
         * постановка событий
         * @private
         */
        _listener: function(){
            var $container = $(this._selectors.container);
            var namespace = "app.logs";
            $container.onOne('click', this._selectors.infoItem, namespace, this._logItemToggle.bind(this));
            $container.onOne('click', this._selectors.resultItemContainer, namespace, this._jsonToggleClick.bind(this));

        },
        /**
         * Пересчитываем высоту если окрывается закрывается json
         * @param {Event} e
         * @private
         */
        _jsonToggleClick: function(e){
            var $result_item = $(e.currentTarget).parents(this._selectors.resultItem);
            var id = $result_item.data('id');
            var eventObject = {
                currentTarget: $(this._selectors.infoItem + '[data-id="'+id+'"]')
            };
            this._logItemToggle(eventObject, true);
        },
        /**
         *
         * открывает/закрывает подробную информацию лога
         * @param  {Event} e
         * @param {Boolean} [state] если передать state  - true то информация откроеться в люблм случае, ели false то в любом случае закроется
         * @private
         */
        _logItemToggle: function(e, state){
            if(e && e.preventDefault){
                e.preventDefault();
            }
            var $item = $(e.currentTarget);
            var id = $item.data('id');
            var $result_item = $(this._selectors.resultItem + '[data-id="'+id+'"]');
            var result_item_height = $result_item.outerHeight();
            if((!_.isBoolean(state) && result_item_height < 10) || (_.isBoolean(state) && state)){
                var $result_container = $result_item.find(this._selectors.resultItemContainer);
                if(!$result_container.hasClass('data-init')){
                    /**
                     * Инициализируем отоброжение данных
                     */
                    if(this.data[id] && this.data[id].params){
                        $result_container.find(this._selectors.params).jsonViewer(this.data[id].params);
                    }
                    if(this.data[id] && this.data[id].result){
                        $result_container.find(this._selectors.result).jsonViewer(this.data[id].result);
                    }
                    $result_container.addClass('data-init');
                }
                var container_height = $result_container.outerHeight();
                $result_item.css('height', container_height);
            }else if((!_.isBoolean(state) && result_item_height > 0) || (_.isBoolean(state) && state)){
                $result_item.css('height', '');
            }
        }
    };
})();