/**
 * Created by ursus on 14.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    'use strict';

    app.header = {
        _init: false,
        _open: false,
        _selectors: {
            container: '.header',
            dropdownItem: '.header-item-dropdown',
            dropdownItemText: '.header-item-dropdown-text',
            dropdownContent: '.header-item-dropdown-content',
            dropdownWrap: '.header-item-dropdown-content-wrap',
            messageNumber: '[href="/chat"] .header-item__users-count',
            balance: '.header-item.header-money .number',
            linkLoguot: '.header-loguot',
            formLogout: '.header-form-logout'
        },
        _elements: {},


        init: function(){
          if($(this._selectors.container).length && !this._init){
              this._init = true;
              this._elements = app.elementsParse(this._selectors);
              this._listeners();
          }
        },
        _listeners: function () {
            this._elements.dropdownItemText.click(this._toggleDropdown.bind(this));
            this._elements.linkLoguot.click(this._logout.bind(this));

            $('body').click(this._closeDropdown.bind(this));
            $('body').on(app.notification.events.update, this._dataUpdate.bind(this));
        },
        _dataUpdate: function(e, data){
            this._messagesUpdate(data);
            this._balanceUpdate(data);
        },
        _messagesUpdate: function(data){
            if(data.message){
                var thread = {};
                var count_thread = _.filter(data.message, function (message) {
                   if(!thread[message.thread_id]){
                       thread[message.thread_id] = message.thread_id;
                       return true;
                   }
                   return false;
                });
                var count = count_thread.length ? '(' + count_thread.length + ')' : '';
                this._elements.messageNumber.text(count);
            }
        },
        _balanceUpdate: function(data){
            if(data.balance && this._elements.balance.length){
                this._elements.balance.text(app.helpers.priceFormat(parseFloat(data.balance), 0));
            }
        },
        _logout: function(e){
            if(e && e.preventDefault){
                e.preventDefault();
            }
            this._elements.formLogout.submit();
        },
        _closeDropdown: function(e){
            var $item = $(e.target);
            if(!$item.closest(this._selectors.dropdownItem).length){
                this._elements.dropdownItem.removeClass('hover');
            }
        },
        _toggleDropdown: function (e, state) {
            if(e && e.preventDefault){
                e.preventDefault();
            }

            var $item = $(e.currentTarget).parents(this._selectors.dropdownItem);
            state = _.isBoolean(state) ? state : !$item.hasClass('hover');
            $item.toggleClass('hover', state);
           /* var $wrapper = $item.find(this._selectors.dropdownWrap);
            var $content = $item.find(this._selectors.dropdownContent);
            var wrapHeight = $wrapper.outerHeight();
            if((!_.isBoolean(state) && wrapHeight <= 0) || (_.isBoolean(state) && state)){
                var contentHeight = $content.outerHeight();
                $wrapper.css('height', contentHeight + 10);
                $wrapper.addClass('open');
                this._open = true;
            }else if((!_.isBoolean(state) && wrapHeight > 0) || (_.isBoolean(state) && !state)){
                $wrapper.css('height', '');
                $wrapper.removeClass('open');
                this._open = this._elements.dropdownWrap.filter('.open').length ? true : false;
            }*/
        }

    };
})();