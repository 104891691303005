(function(){
    "use strict";
    app.auctions = {
        /**
         * селекторы модуля
         */
        _selectors: {
            container: '.auctions, .seller-lots-new',

            menu: '.menu'
        },
        events:{
            items_update: 'auction_items_update'
        },
        /**
         * jQuery элементы модуля
         */
        _elements:{},
        /**
         * Флаг инициализации модуля
         */
        _init: false,
        /**
         * Функция нмциализации модуля
         */
        init: function(){
            var $container = $(this._selectors.container);
            if($container.length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $container);
                this._listener();
            }
        },
        /**
         * Постановка обраьтчиков событий
         * @private
         */
        _listener: function(){
            var $body = $('body');
            var namespace = "auctions";
            $body.onOne(this.events.items_update, '', namespace, this._updateMenu.bind(this));
        },


        _query: false,
        _updateMenu: function(){
            if(this._query){
                this._query.abort();
            }
            var that = this;
            this._query = $.get(location.pathname, {only_menu: true}).done(function (html) {
               $(that._selectors.menu).replaceWith(html);
            }).fail(function(){
               // app.toast.error("Не получилось обновить данные меню");
            });
        },
        /**
         * Отрабатываем ресайз браузера
         */
        resize: function(){}
    };
})();