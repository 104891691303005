(function(){
	"use strict";

	app.lotDiagnostics = {
/**
* Селекторы модуля
*/
	_selectors: {
		container: '.seller-lot-diag',
    radio_item: ':radio'
	},

	_elements: {},

	_init: false,

	init: function(){
		if($(this._selectors.container).length && !this._init){
		this._init = true;
		this._elements = app.elementsParse(this._selectors, this._selectors.container);
		this._listeners();
		}
	},
/**
* Постановка обработчиков событий
*/
	_listeners: function(){
    this._elements.radio_item.on('change', this._toggle_comment.bind(this));
	},

  /**
   * _toggle_comment - show or hide comment field depending on picked radio option
   *
   * @param  {Event} e description
   */
  _toggle_comment: function(e) {
    var choice = $(e.target).val();
    var $comment_box = $(e.target).parent().siblings('.lot-diag-comment');
    if(choice === "0") {
      $comment_box.removeClass('comment-field-hidden');
    } else if(choice === "1") {
      $comment_box.addClass('comment-field-hidden');
    }
  }
	};
})();
