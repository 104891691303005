/**
 * Created by ursus on 13.06.2016.
 */
/* globals _ */
/* globals app */
(function () {
    "use strict";
    /**
     * Модуль форм
     *@module app.forms
     * @memberOf app
     */
    app.forms = {};

    /**
     * Селекторы иодуля
     * @type {{flyInputLabel: string, flyInput: string, labelText: string}}
     * @private
     */
    var _selectors = {
            flyInputLabel: '.form-fly-input',
            flyInput: '.form-fly-input input',
            labelText: '.label-text'
        },
        /**
         * Флаг инициализации модуля
         * @type {boolean}
         * @private
         */
        _init = false;

    /**
     * @method init
     * @desc Init module form
     */
    app.forms.init = function () {
        if (!_init) {
            this._listener();
        }
        _init = true;
        //this.flyInputInit();
        /*  */
        //this.parseInput();
        //this.parseTextarea();
        /*this.range();*/
        this.elementsInit();

    };
    app.forms.range = function () {
        $('.range-block input.range').ionRangeSlider({});
    };

    app.forms.inputPriceInit = function () {
        $('input.price').each(function () {
            var $input = $(this);
            var $visibleInput = $input.clone();
            $input.attr('type', 'hidden');
            var price = $input.val();
            if (_.isString(price)) {
                price = price.replace(/\s/g, '');
            }
            $input.val(price);
            $visibleInput.attr('name', '');
            $input.parent().append($visibleInput);
        });
    };

    // app.forms._phoneInputFocus = function(e){
    //     var $input = $(e.currentTarget);
    //     if(!$input.data('mask-init')){
    //         $input.data('mask-init', true);
    //         $input.inputmask('+7 (999) 999-99-99', {
    //             'autoUnmask': true,
    //             "clearIncomplete": true
    //         });
    //     }
    // };

    app.forms._rangeChange = function () {
        var el = $(this);
        var block = el.parents('.range-block');
        block.find('.from').val(el.data('from'));
        block.find('.to').val(el.data('to'));
    };
    /**
     * Инициализация и реиницилизация select
     * @method selectInit
     */
    app.forms.selectInit = function () {
        $('select').not('.color, .select-search').select2({
            minimumResultsForSearch: Infinity,
            placeholder: "Выберите"
        });
        $('select.color').not('.select-search').select2({
            minimumResultsForSearch: Infinity,
            templateResult: this.colorTemplate,
            templateSelection: this.colorTemplate,
            placeholder: "Выберите"
        });
        // $('.select-search').select2({
        //     placeholder: "Выберите"
        // });
        $('select.select-search').selectize({

        });


    };
     app.forms.dateTimeInit = function () {
         var that = this;
       $('.datetime:not(.no-initial-init)').each(function(){
           that._dateTimeInit({currentTarget: this});
       });
     };
    /**
     * первичная инициализация даты с записью значения
     *
     */
    app.forms.datesInit = function () {
        var self = this;
        $('input.date').each(function () {
            if ($(this).val()) {
                $(this).fdatepicker({
                    initialDate: $(this).val() || new Date(),
                    format: 'dd.mm.yyyy',
                    disableDblClickSelection: true,
                    language: 'ru',
                    startDate: "01.01.1900",

                });

                $(this).data('date-init', true);
                var name = $(this).attr('name');
                $(this).attr('name', '');
                form_id++;
                var id = form_id;
                $(this).attr('data-form-id', id);
                $(this).after(self._inputDateTempalte({name: name, id: id}));

                var val;
                var strDate = $(this).val().split('.');
                if (strDate.length == 3) {
                    val = strDate[1] + '/' + strDate[0] + '/' + strDate[2];
                }
                var date = new Date(val);
                var seconds = date.getTime() / 1000;
                $(this).siblings('input[type="hidden"]').val(seconds);
            }
        });

    };

    /**
     * первичная инициализация даты с записью значения
     *
     */

    app.forms.yearDatesInit = function () {
        var self = this;

        $('input.date-year').each(function () {
            var initDate;
            var $input = $(this);
            var val = $input.val();
            if (val) {
                var time =( new Date(val)).getTime();
                initDate = !isNaN(time) && time > 0 ? val : false;
            }

            var dateOptions = {
                format: 'yyyy',
                disableDblClickSelection: true,
                language: 'ru',
                minView: 'decade',
                startView: 'decade'
            };

            if(!initDate) {
                val = '';
            }

            dateOptions.initialDate = initDate || '';

            $input.fdatepicker(dateOptions);

            $input.data('date-init', true);
            var name = $input.attr('name');
            $input.attr('name', '');
            form_id++;
            var id = form_id;
            $input.attr('data-form-id', id);
            $input.after(self._inputDateTempalte({name: name, id: id}));
            $input.val(val.substring(0, 4));
            var date = new Date(val);
            var seconds = date.getTime() / 1000;
            $input.siblings('input[type="hidden"]').val(seconds || '');

        });
    };

    app.forms.colorTemplate = function (item) {
        if (!item.id) {
            return item.text;
        }
        var $item = $(item.element);
        var color = $item.data('color');
        return $(
            '<span class="select-item-color-wrap">' + item.text + '<span class="select-item-color" style="background-color: ' + color + ';"></span></span>'
        );
    };

    app.forms.elementsInit = function () {
        this.selectInit();
        $('.selectize-input').each(function(id, input) {
            var $input = $(input);
            if(!$input.find('.filter-item-clear').length) {
                $input.append($('<span class="filter-item-clear"></span>'));
            }
        })
        this.checkboxInit();
        this.radioInit();
        this.inputPriceInit();
        this.datesInit();
        this.dateTimeInit();
        this.yearDatesInit();
        this._phoneInputsInit();
        // this.maskInit();
    };

    // /**
    //  * Инициализация инпутов с маской
    //  */
    // app.forms.maskInit = function(){
    //     $('.input-mask').each(function(){
    //       var $item = $(this),
    //           mask = $item.data('masked');
    //         if(mask){
    //             $item.mask(mask);
    //         }
    //     });
    // };

    app.forms._phoneInputFocus = function(e){
        var $input = $(e.currentTarget);
        this._phoneInputInit($input);
    };

    app.forms._phoneInputsInit = function(){
        var that = this;
        $('input.phone').each(function(){
            that._phoneInputInit($(this));
        });
    };

    app.forms._phoneInputInit = function($input){
        if(!$input.data('mask-init')){
            $input.data('mask-init', true);
            $input.inputmask('+7 (999) 999-99-99', {
                // 'autoUnmask': true,
                "clearIncomplete": true
            });
        }
    };
    /**
     * Инициализация input
     * @method parseInput
     * @param [parent] {jQuery | String | HTMLElement} Узел от которого начнется поиск элементов
     */
    app.forms.parseInput = function (parent) {
        var _parent = $(parent).length ? $(parent) : $('body');
        _parent.find('input')
            .not(':checkbox')
            .not(':radio')
            .each(function (index, element) {
                var $elem = $(element);
                var $label = $elem.parent('label');
                if (!$label.hasClass('form-fly-input')) {
                    var text = $label.text();
                    $label.html('<span class="label-text">' + text + '</span><span class="form-fly-message"></span>');
                    $label.find('.label-text').after($elem);
                    $label.addClass('form-fly-input');
                }
            });
    };
    /**
     * Инициализация textarea
     * @method parseTextarea
     * @param [parent] {jQuery | String | HTMLElement} Узел от которого начнется поиск элементов
     */
    app.forms.parseTextarea = function (parent) {
        var _parent = $(parent).length ? $(parent) : $('body');
        _parent.find('textarea').each(function (index, elem) {
            var $elem = $(elem);
            var $label = $elem.parent('label');
            if (!$label.find('.label-text').length) {
                var text = $label.text();
                $label.html('<span class="label-text">' + text + '</span>');
                $label.append($elem);
                $label.addClass('form-item');
            }
        });
    };
    /**
     * Ослеживаем резайз окна
     * @method resize
     */
    app.forms.resize = function () {
        this.selectInit();
    };


    /**
     * Инициализация checkbox
     * @method checkboxInit
     * @param [$parent] {jQuery | String | HTMLElement} Узел от которого начнется поиск элементов
     */
    app.forms.checkboxInit = function ($parent) {
        var _parent = $($parent).length ? $($parent) : $('body');
        _parent.find('input[type=checkbox]').each(function (index, element) {
            var $elem = $(element);
            var $label = $elem.parent("label");
            $label.toggleClass('checked', $elem.prop('checked'));
            $label.toggleClass('disabled', $elem.prop('disabled'));
            if (!$label.hasClass('checkbox')) {
                var text = $label.text();
                $label.addClass('checkbox');
                $label.html('<span class="checkbox-text">' + text + '</span>');
                $label.append($elem);
            }
        });
    };

    /**
     * Инициализация radio
     * @method radioInit
     * @param [$parent] {jQuery | String | HTMLElement} Узел от которого начнется поиск элементов
     */
    app.forms.radioInit = function ($parent) {
        var _parent = $($parent).length ? $($parent) : $('body');
        _parent.find('input[type=radio]').each(function (index, element) {
            var $elem = $(element);
            var $label = $elem.parent("label");
            $label.toggleClass('checked', $elem.prop('checked'));
            $label.toggleClass('disabled', $elem.prop('disabled'));
            if (!$label.hasClass('radio')) {
                var text = $label.text();
                $label.addClass('radio');
                $label.html('<span class="checkbox-text">' + text + '</span>');
                $label.append($elem);
            }
        });
    };


    /**
     * Обрабатываем изменение input date
     * @param {Event} e
     * @private
     */
    app.forms._dateChange = function (e) {
        var $input = $(e.currentTarget);
        var val = $input.val();
        var strDate = val.split('.');
        if (strDate.length == 3) {
            val = strDate[1] + '/' + strDate[0] + '/' + strDate[2];
        }
        if ($input.hasClass('datetime')) {
            val += ':00';
        }
        var date = new Date(val);
        var id = $input.attr('data-form-id');
        console.log($('[type="hidden"][data-form-id="' + id + '"]'));
        $('[type="hidden"][data-form-id="' + id + '"]').val(Math.ceil(date.getTime() / 1000));

    };

    /**
     * Устанавливает обработчики событий
     * @method _listener
     * @private
     */
    app.forms._listener = function () {
        var $body = $('body');
        $body.on('focus', 'input.no-input', this._noInputFocus.bind(this));
        $body.on('change', 'input.no-input', this._noInputChange.bind(this));
        $body.on('focus', 'input.phone', this._phoneInputFocus.bind(this));
        $body.on('change', 'input[type=checkbox]', this.checkboxChange.bind(this));
        $body.on('change', 'input[type=radio]', this._radioChange.bind(this));
        $body.on('focus', 'input, select, textarea', this._removeInvalid.bind(this));
        $body.on('click', '.form-item-file', this._inputFileActivate.bind(this));
        $body.on('change', 'input[type=file]', this._changeFile.bind(this));
        $body.on('focus', 'input.date', this._dateInit.bind(this));
        $body.on('focus', 'input.date-year', this._dateYearInit.bind(this));
        $body.on('focus', 'input.datetime', this._dateTimeInit.bind(this));
        $body.on('focus', 'input.phone', this._phoneMask.bind(this));
        $body.on('focus', 'input.validate-number', this._phoneMask.bind(this));
        $body.on('focus', 'input.validate-latin', this._validateLat.bind(this));
        $body.on('input', 'input.price', this._priceValidate.bind(this));
        $body.on('focus', '[name="car[PTS_VIN]"]', this._vinValidate.bind(this));
        $body.on('input', '[data-input-validate]', this.inputValidate.bind(this));
        $body.on('change', 'input.date, input.datetime, input.date-year', this._dateChange.bind(this));
        $body.on('changeDate', 'input.date, input.datetime, input.date-year', function(e) {
            setTimeout(function() {
                $(e.currentTarget).fdatepicker('place');
            },50);

        });
        $body.on('reset', 'form', function (e) {


            setTimeout(function () {
                $(e.currentTarget).find('select').each(function () {
                    $(this).val("");
                });
                $(e.currentTarget).find('select').trigger('change');
            }, 500);
        });
        /*$body.on('submit', 'form.ajax-form', function(e){
         e.preventDefault();
         app.ajaxSubmit($(e.currentTarget), {});
         });*/
        $body.on('inputs:update', function () {
            this.checkboxInit();
        }.bind(this));

    };

    app.forms.inputValidate = function(e){
        var $input = $(e.currentTarget),
            regexp = new RegExp($input.data('inputValidate')),
            val = $input.val();
        if(val) {
            if (val.replace(regexp, '').length) {
                $input.val($input.data('val') || "");
            }else{
                $input.data('val', val);
            }
        }
    };

    app.forms._validateLat = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('validate-vin-init')) {
            $input.data('validate-number-init', true);

            $input.on('input', function (e) {
                var $input = $(e.currentTarget),
                    val = $input.val(),
                    old_val = $input.data('from-val') || '',
                    valid = true;
                var reg = /[^0-9a-zA-Z]/g;
                if (!_.isNull(val.match(reg))) {
                    valid = false;
                }
                if (!valid) {
                    $input.val(old_val);
                } else {
                    $input.data('from-val', val);
                }
            });
        }
    };
    app.forms._validateNumber = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('validate-vin-init')) {
            $input.data('validate-number-init', true);

            $input.on('input', function () {
                var val = $input.val();
                var valid = true;
                var reg = /[^0-9]/g;
                if (!_.isNull(val.match(reg))) {
                    valid = false;
                }
                if (valid) {
                    var max = $input.data("max");
                    var min = $input.data("min");
                    if (+val > +max || +val < +min) {
                        valid = false;
                    }
                }

                if (!valid) {
                    var old_val = $input.data('from-val') || '';
                    $input.val(old_val);
                } else {
                    $input.data('from-val', val);
                }
            });
        }
    };

    app.forms._vinValidate = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('validate-vin-init')) {
            $input.data('validate-vin-init', true);

            $input.on('input', function (e) {
                var val = $input.val() || '';
                var valid = true;
                var old_val = $input.data('from-val') || '';
                if (val.length > 17) {
                    valid = false;
                }
                var reg = /[^0-9a-zA-Z]/g;
                if (valid && !_.isNull(val.match(reg))) {
                    valid = false;
                }
                reg = /[oO]/g;
                if (valid && !_.isNull(val.match(reg))) {
                    valid = false;
                }
                if (!valid) {
                    $input.val(old_val);
                } else {
                    $input.data('from-val', val);
                }
            });
        }
    };

    app.forms._phoneMask = function (e) {
/*        $(e.currentTarget).mask('+7 (000) 000-00-00');*/
    };

    app.forms._changeFile = function (e) {
        var files = e.currentTarget.files;
        var $files = $(e.currentTarget);
        $files.data('add-files', true);
        var $container = $files.parents('.label').find('.img-container');
        this._loadFile(files, 0, $container);
    };

    app.forms._loadFile = function (files, index, $container) {
        if (index < files.length) {
            var reader = new FileReader();
            reader.onload = this._onloadFile.bind(this, files, index, $container);
            reader.readAsDataURL(files[index]);
        }
    };

    var form_id = 0;

    app.forms._inputDateTempalte = _.template('<input type="hidden" value="" name="<%=name%>" data-form-id="<%=id%>">');
    /**
     *
     *Инициализация инпута даты
     * @param {Event} e
     * @private
     */
    app.forms._dateYearInit = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('date-init')) {
            $input.fdatepicker({
                format: 'yyyy',
                disableDblClickSelection: true,
                language: 'ru',

            });
            $input.data('date-init', true);
            var name = $input.attr('name');
            $input.attr('name', '');
            form_id++;
            var id = form_id;
            $input.attr('data-form-id', id);
            $input.after(this._inputDateTempalte({name: name, id: id}));
        }
    };
    /**
     *
     *Инициализация инпута даты
     * @param {Event} e
     * @private
     */
    app.forms._dateInit = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('date-init')) {
            $input.fdatepicker({
                format: 'dd.mm.yyyy',
                disableDblClickSelection: true,
                language: 'ru',
                startDate: "01.01.1900",

            });
            $input.data('date-init', true);
            var name = $input.attr('name');
            $input.attr('name', '');
            form_id++;
            var id = form_id;
            $input.attr('data-form-id', id);
            $input.after(this._inputDateTempalte({name: name, id: id}));
        }
    };

    /**
     * Разбивает цену на разряды
     * @param e
     * @private
     */
    app.forms._priceValidate = function (e) {
        var $item = $(e.currentTarget);
        var val = $item.val();
        if (!$item.data('val')) {
            $item.data('val', '');
        }
        if (val) {
            val = val.replace(/\s/g, '');
            if (isNaN(+val) || +val > 1000000000000) {
                $item.val($item.data('val'));
            } else {
                var price = app.helpers.priceFormat(+val, 0);
                $item.data('val', price);
                $item.val(price);
                $item.parent().find('input[type=hidden].price').val(val);
            }
        } else {
            $item.data('val', '');
        }
    };

    /**
     *
     *Инициализация инпута даты-времени
     * @param {Event} e
     * @private
     */
    app.forms._dateTimeInit = function (e) {
        var $input = $(e.currentTarget);
        if (!$input.data('date-init')) {
            var time =( new Date($input.val() * 1000)).getTime();
            var initDate = !isNaN(time) && time > 0 ? time : false;
            var dateOptions = {
                format: 'dd.mm.yyyy hh:ii',
                disableDblClickSelection: true,
                language: 'ru',
                pickTime: true
            };
            var initialDate;
            if(initDate){
                initialDate = new Date(initDate);

            }
            $input.fdatepicker(dateOptions);
            if(initialDate){
                $input.fdatepicker('update', initialDate);
            }
            $input.data('date-init', true);
            var name = $input.attr('name');
            $input.attr('name', '');
            form_id++;
            var id = form_id;
            $input.attr('data-form-id', id);
            $input.after(this._inputDateTempalte({name: name, id: id}));
        }
    };

    var itemFileTemplate = _.template('<a href="" class="form-item-file-img" style="background-image: url(<%=url%>)"> </a>');

    app.forms._onloadFile = function (files, index, $container, e) {
        $container.append(itemFileTemplate({url: e.target.result}));
        index = index + 1;

        // загрузка изображения в большую форму.
        var $large_img_form = $container.siblings('.image-background');
        if($large_img_form.length && ($large_img_form.css('background-image')) == 'none') {
          $large_img_form.css('background-image', 'url(' + e.target.result + ')');
        }
        //

        this._loadFile(files, index, $container);
    };

    app.forms._inputFileActivate = function (e) {
        e.preventDefault();
        var $label = $(e.currentTarget).parents('.label');
        var $input = $label.find('input[type=file]').last();
        $label.append($input.clone());
        $input.trigger('click');
    };
    app.forms._removeInvalid = function (e) {
        $(e.currentTarget).parents('label').removeClass('invalid');
    };

    app.forms._noInputFocus = function(e){
        var $input = $(e.currentTarget);
        this._noInputInit($input);
    };

    app.forms._noInputInit = function($input){
        if(!$input.data('no-input-init')){
            !$input.data('no-input-init', true);
            $input.data('no-input-value', $input.val());
            $input.on('input', function(e){
                e.preventDefault();
                $input.val($input.data('no-input-val') || '');
            });
        }
    };

    app.forms._noInputChange = function(e){
        var $input = $(e.currentTarget);
        $input.data('no-input-val', $input.val());
    };
    /**
     * Ослеживаем состояние смены чекбокса
     * @method checkboxChange
     * @param event {Event}
     * @param [state] {Boolean}
     * @private
     */
    app.forms.checkboxChange = function (event, state) {
        var $checkbox = $(event.currentTarget);
        var $label = $checkbox.parents('label.checkbox');
        var _state = _.isBoolean(state) ? state : $checkbox.prop('checked');
        $label.toggleClass('checked', _state);
        $checkbox.prop('checked', _state);
    };

    /**
     * Ослеживаем состояние смены radio
     * @method _radioChange
     * @param event {Event}
     * @private
     */
    app.forms._radioChange = function (event) {
        var $radio = $(event.currentTarget);
        var $label = $radio.parents('label.radio');
        var checked = $radio.prop('checked');
        if (checked) {
            var name = $radio.attr('name');
            $('input[name="' + name + '"]').parents('label.radio').removeClass('checked');
        }
        $label.toggleClass('checked', checked);

    };
})();
