/**
 * Created by ursus on 12.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";
    app.pageReload = {
        _init: false,
        _selectors: {
            item: '.page-reload'
        },
        init: function () {
            if (!this._init) {
                this._listener();
                this._reload();
            }
        },
        _listener: function () {
            $('body').on(app.ajaxForm.events.success, this._reload.bind(this));
        },
        _reload: function () {
            var $item = $(this._selectors.item);
            if($item.length){
                var url = $item.data('url');
                var time = parseInt($item.data('time')) || 1;
                if(url && time){
                    setTimeout(function(){
                        location.assign(url);
                    }, time);
                }
            }
        }
    };
})();