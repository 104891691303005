/**
 * Created by ursus on 24.07.2016.
 */

(function(){
    "use strict";
    
    app.filter = {
        _init: false,
        _selectors: {
            container: '.filter',
            content: '.filter-content',
            contentWrap: '.filter-content-wrapper',
            clear: '.filter-clear',
            clear_one: '.filter-item-clear',
            toogler: '.filter-toggler'
        },
        _elements: {},
        _config: {
            classShow: 'show',
            show: false,
            storageKey: 'lemoncar-filter-state'
        },
        init: function($parent){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors, $parent);
               // this._storageRead();
                this._filterInit();
                this._listener();
            }
        },
        _listener: function(){
          this._elements.toogler.onOne('click', '', 'filters',this._toggleFilter.bind(this));
          this._elements.container.onOne('change', 'input, select, textarea', 'filters',  this._formSubmit.bind(this));
            this._elements.clear.onOne('click', '', 'filters', this._clearFilters.bind(this));
            $('body').on('click', this._selectors.clear_one, this._clearFilter.bind(this));
        },
        _formSubmit: function(){
            if(this._formSubmit.timeout){
                clearTimeout(this._formSubmit.timeout);
            }
            var that = this;
            this._formSubmit.timeout = setTimeout(function () {
                var $form  = that._elements.container.find('form');
                var params = $form.serialize();
                history.pushState('', '', '?' + params);
                $form.submit();
            }, 500);

        },
        _filterInit: function(){
            if(this._config.show){
                //this._toggleFilter({},true);
            }
        },
        _toggleFilter: function(e, state){
            if(e && e.preventDefault){
                e.preventDefault();
            }
            var _classState = this._elements.container.hasClass(this._config.classShow);
            var boolean = _.isBoolean(state);
            if((_classState && !boolean) || (boolean && !state)){
                this._elements.container.removeClass(this._config.classShow);
                this._elements.contentWrap.css('height', '');
                this._elements.contentWrap.css('overflow', '');
                this._config.show = false;
            }else if((!_classState && !boolean) || (boolean && state)){
                this._elements.container.addClass(this._config.classShow);
                var height = this._elements.content.outerHeight();
                this._elements.contentWrap.css('height', height);
                setTimeout(function() {
                    this._elements.contentWrap.css('overflow', 'visible');
                }.bind(this), 500)

                this._config.show = true;
            }
            this._storageWrite();
        },

        _clearFilters: function(e) {
            var $filters = $(e.currentTarget).closest('.filter-controls').siblings('.filter-content-wrapper').find('.filter-item');
            $filters.each(function(id, filter) {
               var $select = $(filter).find('select');
                $select[0].selectize.clear();
            });
        },

        _clearFilter: function(e) {
            e.preventDefault();
            e.stopPropagation();
            var $select = $(e.currentTarget).closest('.selectize-control').siblings('select');
            if($select.length) {
                $select[0].selectize.clear();
            }

        },

        _storageRead: function(){
            var state = localStorage.getItem(this._config.storageKey);
            if((_.isBoolean(state) && state) || state === "true"){
                this._config.show = true;
            }
        },
        _storageWrite: function(){
            localStorage.setItem(this._config.storageKey, this._config.show);
        }
    };
})();