/**
 * Created by ursus on 06.07.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    /**
     * Ставит едиственное событие
     * @memberOf jQuery
     * @param event {string} Имя события
     * @param selector  {String} Селектор (может быть пустой строкой)
     * @param namespace {String} Немспейс функции (по нему будет осуществляться проверка есть ли уже данный обработчик)
     * @param handler {Function} Обработчик события
     */
    $.fn.onOne = function(event, selector, namespace, handler){
        $(this).each(function(){
            var $el = $(this);

            var data = $el.data(namespace);
            if(data){
                for(var i = 0, len = data.length; i < len; i++){
                    if(data[i].event === event && data[i].selector === selector){
                        return;
                    }
                }
            }


            $el.on(event, selector, handler);
            data = data || [];
            data.push({event: event, selector: selector});
            $el.data(namespace, data);
        });
    };

    jQuery.expr[":"].Contains = jQuery.expr.createPseudo(function(arg) {
        return function( elem ) {
            return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });
})();