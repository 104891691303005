/**
 * Created by ursus on 08.07.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    'use strict';

    app.validator = {
        config: {
            selectorFieldItem: 'label',
            selectorFieldItemMessage: '.label-message',
            classInvalid: 'invalid',
            classValid: 'valid',
        },
        /**
         *
         * @param data
         * @param config
         */
        validate: function (data, config) {
            var validate = true;
            _.each(config, function (rules, name) {
                if(data.hasOwnProperty(name)){
                    var validate = this.parseRules(rules);
                    if(!this.valid(validate, data[name])){
                        validate = false;
                    }
                }
            }.bind(this));
            return validate;
        },
        valid: function(rules, data){
            var validate = true;
            _.each(rules, function(rule){
                if(!rule(data)){
                    validate = false;
                }
            });
            return validate;
        },
        parseRules: function (rulesStr) {
            var rules = rulesStr.trim().split(' ');
            var resultRulles = [];
            _.each(rules, function (rule) {
                resultRulles.push(this.parseRule(rule));
            }.bind(this));
            return resultRulles;
        },
        parseRule: function(rule){
            if(rule.indexOf("(") > -1){
                var _rule = rule.substring(0, rule.indexOf("("));
                var _val = rule.substring(rule.indexOf("(") + 1, rule.indexOf(")") -1);
                if(_.isFunction(this.validateFunction[_rule])){
                    return this.validateFunction[_rule].bind(this, _val);
                }else{
                    console.warn("Функция валидации не найдена - " + _rule);
                    return this.defaultFunction;
                }
            }else{
                if(_.isFunction(this.validateFunction[rule])){
                    return this.validateFunction[rule];
                }else{
                    console.warn("Функция валидации не найдена - " + rule);
                    return this.defaultFunction;
                }
            }
        },
        validateFunction: {
            date: function (val) {
                return _.isDate(val);
            },
            float: function (val) {
                return validator.isFloat(val);
            },
            int: function (val) {
                return validator.isInt(val);
            },
            number: function (val) {
                return validator.isNumeric(val);
            },
            string: function (val) {
                return _.isString(val);
            },
            noEmpty: function (val) {
                return !_.isEmpty(val);
            },
            boolean: function (val) {
                return _.isBoolean(val);
            },
            required: function (val) {
                if(_.isString(val)){
                    val = val.trim();
                }
                return !!val;
            },
            email: function (val) {
                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                return pattern.test(val);
            },
            phone: function (val){
                return validator.isMobilePhone(val);
            },
            valid: function(val, dataVal){
                return dataVal;
            },
            checked: function (val, dataVal, el) {
                return $(el).prop("checked");
            }
        },
        invalidTextFunction: {
            date: function (val) {
                return 'Значение не является датой';
            },
            float: function (val) {
                return 'Значение не является числом с плавующей точой';
            },
            int: function (val) {
                return 'Значение не является целым числом';
            },
            number: function (val) {
                return 'Значение не является числом';
            },
            string: function (val) {
                return 'Значение не является строкой';
            },
            noEmpty: function (val) {
                return 'Значение не не должно быть пустым';
            },
            boolean: function (val) {
                return 'Значение не не булевое';
            },
            required: function (val) {
                return "Это поле обязательно для заполнения";
            },
            checked: function (val) {
                return "Это поле обязательно для заполнения";
            },
            email: function (val) {
                return "Неправильный формат заполнения";
            },
            phone: function (val){
                return "Неправильный формат заполнения";
            },
        },
        defaultFunction: function(){
            return true;
        },
        formValidate: function(array, $form){
            var allValid = true;
            var that = this;
            $form.find('input, select, textarea').each(function(){
                var _elem = $(this);
                var rules = _elem.data();
                var value = _elem.val();
                var _elemValid = true;

                _.each(rules, function (ruleVal, ruleName) {
                    if(_.isFunction(that.validateFunction[ruleName]) && _elemValid){
                          _elemValid = that.validateFunction[ruleName](value, ruleVal, _elem);
                        if(!_elemValid){
                            allValid = false;
                            var text = that.invalidTextFunction[ruleName](value);
                            var $el = _elem.parents(that.config.selectorFieldItem);
                            if(!$el.find(that.config.selectorFieldItemMessage).length){
                                var className = that.config.selectorFieldItemMessage.replace('.', '');
                                $el.append('<div class="'+className+'"></div>');
                            }
                            $el.find(that.config.selectorFieldItemMessage).html(text);
                            _elem.parents(that.config.selectorFieldItem).addClass(that.config.classInvalid).removeClass(that.config.classValid);
                        }else{
                            _elem.parents(that.config.selectorFieldItem).removeClass(that.config.classInvalid).addClass(that.config.classValid);
                        }
                    }
                });
                if(!_elemValid){

                }
            });
            return allValid;
        }
    };

})();