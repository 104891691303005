/**
 * Created by ursus on 22.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";

    app.adminAuctions = {
        _selectors: {
            container: '.admin-auctions',
            actionStopped: '.auction-take-off',
            auctionItem: '.auction-item'
        },
        $container: {},
        init: function(){
            this.$container = $(this._selectors.container);
            if(this.$container.length){
                this._listeners();
            }
        },
        _listeners: function(){
            var namespace = "app.admin_action";
            this.$container.onOne('click', this._selectors.actionStopped, namespace, this._actionStopped.bind(this));
        },
        /**
         *
         * @param {Event} e
         * @private
         */
        _actionStopped: function(e){
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.auctionItem);
            var id = $item.data('auctionId');
            if(id){
                $.post('/admin/auctions/stopped', {auction_id: id}).done(function(data){
                   if(!app.helpers.isError(data)){
                       app.toast.success('Аукцион остановлен');
                       app.helpersRemoveItem($item);
                   }
                }).fail(function(data){
                    app.toast.error(data);
                });
            }
        }
    };
})();