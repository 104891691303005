(function(){
    "use strict";

    app.lot = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.lot-page',
            inspectionSlider: '.inspection-slider .swiper-container'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
                this._inspectionSliderInit();
            }
        },
        /**
         * Иницализирум слайдер осмотра
         * @private
         */
        _inspectionSliderInit: function(){
            var swiper = new Swiper(this._elements.inspectionSlider.get(0), {});
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){

        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();
