/**
 * Created by ursus on 20.09.2016.
 */

/* globals _ */
/* globals cms */
(function () {
    "use strict";
    app.sellerLotNew = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: '.seller-lots-new',
            actionDelete: '.seller-lot-delete',
            actionToChecking: '.seller-lot-checking',
            actionCreateAuction: '.seller-create-auction',
            lotsChecking: '.checkbox-lot-checking',
            lotsDraft: '.checkbox-lot-draft',
            lotItem: '.auction-item',
        },
        _init: false,
        /**
         * Инициалихация модуля
         */
        init: function () {
            if ($(this._selectors.container).length) {
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function () {
            var $container = $(this._selectors.container);
            var namespace = "seller-lot-new";
            $container.onOne('click', this._selectors.actionToChecking, namespace, this._toChecking.bind(this));
            $container.onOne('click', this._selectors.actionDelete, namespace, this._delete.bind(this));
            $container.onOne('click', this._selectors.actionCreateAuction, namespace, this._createAuction.bind(this));
            $container.onOne('change', this._selectors.lotsChecking, namespace, this._toggleChecking.bind(this));
            $container.onOne('change', this._selectors.lotsDraft, namespace, this._toggleDraft.bind(this));
        },
        /**
         * Показываем или скрываем лоты со статусом на соглаовании
         * @param {Event} e
         * @private
         */
        _toggleChecking: function (e) {
            var $item = $(e.currentTarget),
                state = $item.prop("checked");
            if(state){
                $(this._selectors.lotItem).filter('.checking').show(300);
            }else{
                $(this._selectors.lotItem).filter('.checking').hide(300);
            }


        },
        /**
         * Показываем или скрываем лоты со статусом черновика
         * @param {Event} e
         * @private
         */
        _toggleDraft: function (e) {
            var $item = $(e.currentTarget),
                state = $item.prop("checked");
            if(state){
                $(this._selectors.lotItem).filter('.draft').show(300);
            }else{
                $(this._selectors.lotItem).filter('.draft').hide(300);
            }
        },
        /**
         * Отправляем лот на проверку
         * @param  {Event} e
         * @private
         */
        _toChecking: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.lotItem),
                lot_id = $item.data('lotId');
            if (lot_id) {
                $.post('/seller/lot/checking', {lot_id: lot_id}, this._update.bind(this, function (state) {
                    if (state) {
                        app.toast.success("лот отправлен на согласование");
                        $('body').trigger(app.auctions.events.items_update);
                    }
                }));
            } else {
                app.toast.error('Лот не найден');
            }
        },
        /**
         *  Создаем аукцион
         * @param {Event} e
         * @private
         */
        _createAuction: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.lotItem),
                lot_id = $item.data('lotId');
            if (lot_id) {
                $.post('/seller/lot/create-auction', {lot_id: lot_id}, this._update.bind(this, function (state) {
                    if (state) {
                        app.toast.success("Аукцион создан");
                        $('body').trigger(app.auctions.events.items_update);
                    }
                }));
            } else {
                app.toast.error('Лот не найден');
            }
        },
        /**
         * Удаляем лот
         * @param {Event} e
         * @private
         */
        _delete: function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).parents(this._selectors.lotItem),
                lot_id = $item.data('lotId');
            if (lot_id) {
                $.post('/seller/lot/delete', {lot_id: lot_id}, this._update.bind(this, function (state) {
                    if (state) {
                        app.toast.success("Лот удален");
                        $('body').trigger(app.auctions.events.items_update);
                    }
                }));
            } else {
                app.toast.error('Лот не найден');
            }
        },
        /**
         * Обновляем данные в html
         * @param {Function} callback
         * @param {String} data
         * @private
         */
        _update: function (callback, data) {
            var state = !app.helpers.isError(data);
            if (state) {
                var that = this;
                $.get(location.href, function (data) {
                    if (!app.helpers.isError(data)) {

                        $(that._selectors.container).html(data);
                        $('body').trigger(app.auctions.events.items_update);
                        if (_.isFunction(callback)) {
                            callback(state);
                        }
                    }
                });
            }
        }

    };

})();
