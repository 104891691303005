(function(){
    "use strict";

    app.galleries = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: 'body',
            galleries: '[data-gallery]',
            item: '.gallery-item'
        },

        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
                this._galleryItemInit();
                this._galleriesInit();
            }
        },
        /**
         * Ицилизируем множество галерей
         * @private
         */
        _galleriesInit: function(){
            var galleries = {};
            var that = this;
            this._elements.galleries.each(function(){
               var $item = $(this);
               var gallery_index = $item.data('gallery');
                if(gallery_index){
                    galleries[gallery_index] = '[data-gallery="'+gallery_index+'"]';
                }
            });
            _.each(galleries, function(selector){
                that._galleryInit($(selector));
            });
        },
        /**
         * Инициализируем галерею элементов
         * @private
         */
        _galleryItemInit: function(){
            this._galleryInit  (this._elements.item);
        },
        /**
         * Инициализируем галерею
         * @param $item
         * @param {Object} [config]
         * @private
         */
        _galleryInit: function($item, config){
            config = config || {};
          var _config = $.extend({type: 'image',
              gallery:{
                  enabled:true
              }
          }, config);
            $item.magnificPopup(_config);
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){

        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();