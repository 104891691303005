(function(){
	"use strict";

	app.chat = {
/**
* Селекторы модуля
*/
		_selectors: {
			container: '.chat',
			scrollables: '.chat-scrollable',
			channels_list: '.chat-channels',
			channels: '.chat-channel-item',
			message_list: '.chat-channel-messages',
			text_field_container: '.chat-message-box-wrap',
			form: '.chat-message-box',
			text_field: '.chat-text-field',
			send_button: '.chat-send-button',
			messages_page_loader: '.messages-page-loader.active',
			chats_page_loader:'.channel-page-loader.active',
			upperFade: '.chat-upper-fade',
			lowerFade: '.chat-lower-fade'
		},
/**
* jQuery элементы модуля
*/
		_elements: {},

/**
* Флаг инициализации
*/
		_init: false,
/**
* Инициализация модуля
*/
		init: function(){
			if($(this._selectors.container).length && !this._init){
				this._init = true;
				this._elements = app.elementsParse(this._selectors, this._selectors.container);
				this._elements.scrollables.niceScroll({cursorcolor:"#d1d3d8", autohidemode: false});
				this._listeners();
			}
		},
/**
* Постановка обработчиков событий
*/
		_listeners: function(){
			this._elements.channels_list.on('click', this._selectors.channels, this.getMessages.bind(this));
			this._elements.channels_list.on('scroll', this.updateFades.bind(this));
			this._elements.channels_list.on('scroll', this.loadMoreChats.bind(this));
			this._elements.send_button.on('click', this.sendMessage.bind(this));
			this._elements.message_list.on('scroll', this.loadMoreMessages.bind(this));

		},

		/**
		 * getMessages - pick thread by clicking and mark it as active, load associated messages.
		 *
		 * @param  {Event} e clicked element
		 */
		getMessages: function(e) {
			var self = this;
			$(this._selectors.channels).removeClass('active');
			this._elements.text_field_container.addClass('hidden');
			this._elements.text_field.val('');
			this._elements.message_list.html('');
			var $item = $(e.currentTarget);
			var itemData = $item.data();
			var params = {entry_type: itemData.entryType, entry_id: itemData.entryId, thread_id: itemData.threadId};

			$.get('/chat/messages', params, function(response){

				if(!app.helpers.isError(response)) {
					self._elements.message_list.html(response);
					self._elements.message_list.data(params); // save params for use in paging
					var endOfScroll = self._elements.message_list.prop("scrollHeight");
					self._elements.message_list.scrollTop(endOfScroll);
					$item.addClass('active');
					self._elements.form.find('input[name="entry_type"]').val(params.entry_type);
					self._elements.form.find('input[name="entry_id"]').val(params.entry_id);
					self._elements.form.find('input[name="thread_id"]').val(params.thread_id);
					self._elements.text_field_container.removeClass('hidden');


					var unreadMessages = self._elements.message_list.children('.chat-unseen');
					self.markAsRead(unreadMessages, $item);

				}
			});
		},

		/**
		 * loadMoreMessages - load more messages when srolling close to top of message list
		 *
		 */
		loadMoreMessages: function() {
			var self = this;
			if(this._elements.message_list.scrollTop() < 20) {
				var current_page = parseInt($(this._selectors.messages_page_loader).val());
				var last_page = parseInt($(this._selectors.messages_page_loader).data("lastpage"));
				if(current_page < last_page) {
					$(self._selectors.messages_page_loader).removeClass('active');
					var message_list = this._elements.message_list;
					var data = message_list.data();
					var params = {page: current_page + 1, entry_type: data.entry_type, entry_id: data.entry_id};

					$.get('/chat/messages', params, function(response){
						if(!app.helpers.isError(response)) {
							var oldHeight = self._elements.message_list.prop('scrollHeight');
							self._elements.message_list.prepend(response);
							var newHeight = self._elements.message_list.prop('scrollHeight');
							self._elements.message_list.scrollTop(newHeight - oldHeight);
						}
					});
				}
			}
		},

		/**
		 * loadMoreChats - on scroll load more chats for current user
		 *
		 */
		loadMoreChats: function() {
			var self = this;
			var scrollbar = this._elements.channels_list.getNiceScroll()[0];
			if(scrollbar.scrollvaluemax - scrollbar.scroll.y < 20) {
				var current_page = parseInt($(this._selectors.chats_page_loader).val());
				var last_page = parseInt($(this._selectors.chats_page_loader).data("lastpage"));
				if(current_page < last_page) {

					$(self._selectors.chats_page_loader).removeClass('active');

					$.get('/chat', {page: current_page + 1}, function(response){
						if(!app.helpers.isError(response)) {
							self._elements.channels_list.append(response);
						};
					});
				}
			}
		},

		/**
		 * sendMessage - send message(form) to server and clear text field
		 *
		 * @param  {Event} e
		 */
		sendMessage: function(e) {
			e.preventDefault();
			var self = this;
			var $form = this._elements.form;

			app.ajaxSubmit($form, {successReplace: false, successCallback: function(response) {
				if(!app.helpers.isError(response)) {
					self._elements.text_field.val('');
					self._elements.message_list.append(response);
					self._elements.message_list.scrollTop(self._elements.message_list.prop('scrollHeight'));
					// app.toast.success('Ваше сообщение отправлено администрации');
				}
			}});
		},

		/**
		 * updateFades - add or remove fade-out effect on scroll
		 *
		 */
		updateFades: function() {
			var top = this._elements.channels_list.scrollTop();
			var scrollbar = this._elements.channels_list.getNiceScroll()[0];
			// top fadeout rules
			if(top < 20) {
				this._elements.upperFade.css('display', 'none');
			} else {
				this._elements.upperFade.css('display', 'block');
			}
			// bottom fadeout rules
			if(scrollbar.scrollvaluemax - scrollbar.scroll.y < 20) {
				this._elements.lowerFade.css('display', 'none');
			} else {
				this._elements.lowerFade.css('display', 'block');
			}
		},

		/**
		 * markAsRead - description
		 *
		 * @param  {Array} messages messages that should be marked as read
		 * @param  {jQuery} thread thread we've clicked
		 */
		markAsRead: function(messages, thread) {
			var user_id = this._elements.container.data('currentUser');
			messages.each(function() {
				var self = this;
				var sender_id = $(this).data('sender');
				var message_id = $(this).data('messageId');
				if(user_id !== sender_id){
					$.post('/messages/read', {message_id: message_id}, function(response) {
							if(!app.helpers.isError(response)){
									$(self).removeClass('chat-unseen');
									thread.removeClass('chat-unseen');
									thread.addClass('chat-unanswered');
							}
					});
				}
			});
		}
	};
})();
