/**
 * Created by ursus on 12.06.2016.
 */
(function(){
	/**
	 * Модуль который парсит плесхолдеры и собирает html по странице
	 * @memberOf app
	 * @module
	 * @example 
	 * 	<div class='placeholder-item' data-placeholder-selector='.header'>
	 */
	app.placeholders = {
		/**
		 * Селекторы модуля
		 * @member {} _selector
		 * @prop {string} _selector.placehder селектор плейсхолдера
		 * @private
		 */
		_selectors: {
			placehder: '.placeholder-item'
		},
		/**
		 * Элементы (плейсхолдеры)
		 * @type {jQuery} _items
		 * @private
		 */
		_items: {},
		/**
		 * Инициализация модуля
		 * @public
		 */
		init: function(){
			this._items = $(this._selectors.placehder);
			if(this._items.length){
				this._parse();
				app.header.init();
			}
		},
		/**
		 * Парсит плейсхолдеры
		 * @private
		 */
		_parse: function(){
			this._items.each(function(index, elem){
				var $el = $(elem);
				var contentSelector = $el.data('placeholderSelector');
				var type =  $el.data('placeholderType');
				if(contentSelector){
					var content = $(contentSelector).clone();
					if(content && !$el.data('placeholder-insert')){
						$el.data('placeholder-insert', true);
						if(type && type == "append"){
							$el.append(content);
						}else{
							$el.html(content);
						}
					}
				}
			});
		}
	};

})();