/**
 * Created by ursus on 14.09.2016.
 */

/* globals _ */
/* globals cms */
(function(){
    "use strict";
    toastr.options = {
        "closeButton": true,
    };
    app.toast = {
        _selectors: {
            toastItem: '.toast-item'
        },

        init: function(){
            this.toastParse();
        },
        toastParse: function(){
            var that = this;
            $(this._selectors.toastItem).not('.toast_show').each(function(){
                var $toast = $(this);
                var type = $toast.data('type') || 'info';
                var text = $toast.text();
                var toast_func = that[type];
                $toast.addClass('toast_show');
                if(text && _.isFunction(toast_func)){
                    toast_func(text);
                }
            });
        },
        error: function(message){
            toastr.error(message);
        },
        success: function(message){
            toastr.success(message);
        },
        warning: function(message){
            toastr.warning(message);
        },
        info: function(message){
            toastr.info(message);
        },
    };

    $(document).ajaxComplete(function(){
       app.toast.toastParse();
    });
})();