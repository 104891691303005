(function(){
    "use strict";

    app.notification = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: 'body'
        },
        /**
         * События модуля
         */
        events: {
            update: 'notification.data.update'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Интервал запросов на сервер
         */
        _queryInterval: 0,
        /**
         * Флаг что запорос на сервер отправлен
         */
        _querySend: false,
        /**
         * Инициализация модуля
         */
        init: function(){

            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
                this._setInterval();
            }
        },
        /**
         * Устанапливаем интервал
         * @private
         */
        _setInterval: function(){
            if(this._queryInterval){
                clearInterval(this._queryInterval);
            }
            this._queryInterval = setInterval(this._get.bind(this), 1000);
        },
        /**
         * Кэш данных с сервера
         */
        data: {},
        /**
         * отправляем запрос
         * @private
         */
        _get: function(){
            if(!this._querySend){
                this._querySend = true;
                var that = this;
                $.get('/notification').done(function(data){
                    that._querySend = false;
                    if(_.isString(data)){
                        try {
                            data = JSON.parse(data);
                        }catch (e){
                            console.log(e);
                        }
                    }
                    if(_.isObject(data)){
                        that.data = data;
                        that._elements.container.trigger(that.events.update, that.data);
                    }
                });
            }
        },
        _dataParse: function(){

        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){

        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();