(function(){
    "use strict";
    app.scale = {
        /**
         * Селекторы модуля
         */
        _selectors: {
            container: 'body',
            item:'.scale-item',
            scaleContainer: '.scale-container'
        },
        events: {
            active: 'lemoncars.scale.active'
        },
        /**
         * jQuery элементы модуля
         */
        _elements: {},
        /**
         * Флаг инициализации
         */
        _init: false,
        /**
         * Инициализация модуля
         */
        init: function(){
            if($(this._selectors.container).length){
                this._init = true;
                this._elements = app.elementsParse(this._selectors);
                this._listeners();
            }
        },
        /**
         * Постановка обработчиков событий
         * @private
         */
        _listeners: function(){
            var namescpace = 'lemoncars.scale';
            var $body = $('body');
            $body.onOne('mouseenter mouseleave', this._selectors.item, namescpace, this._hover.bind(this));
            $body.onOne('click', this._selectors.item, namescpace, this._active.bind(this));

        },
        _hover: function(e){
            var $item = $(e.currentTarget);
            if(e.type === 'mouseenter'){
                $item.prevAll().addClass('hover');
                $item.addClass('hover');
            }else{
                $(this._selectors.item).removeClass('hover');
            }
        },

        /**
         *
         * @param {Event} e
         * @private
         */
        _active: function(e){
            e.preventDefault();
            var $item = $(e.currentTarget),
                $container = $item.parents(this._selectors.scaleContainer),
                value = $item.data('key');
            $container.find(this._selectors.item).removeClass('active');
            $item.prevAll().addClass('active');
            $item.addClass('active');
            $container.trigger(this.events.active, [value]);
        },
        /**
         * Функция отрабатывает по ресайзу окна
         */
        resize: function(){
            if(this._init){

            }
        }
    };
})();